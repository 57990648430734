import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 3L3 7v2.5h2v8h2v-8h2v8h2v-8h2v8h2v-8h2v8h2v-8h2V7zm0 2.19l5.201 2.31H6.8zM3 19.5v2h18v-2z" />
    </svg>
  )
}

export default SvgComponent
