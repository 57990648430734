import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor">
        <path d="M19 15.5a1 1 0 01.117 1.993L19 17.5h-3a1 1 0 01-.117-1.993L16 15.5h3z" />
        <path d="M12 12.5H4a4 4 0 00-4 4l.006.205A4.006 4.006 0 004 20.5h8a4 4 0 004-4l-.006-.205A4.006 4.006 0 0012 12.5zm-8 2h8a2 2 0 011.997 1.882l.003.147c0 1.076-.895 1.971-2 1.971H4a2 2 0 01-1.997-1.882L2 16.471c0-1.076.895-1.971 2-1.971z" />
        <path d="M15 6.5l.001 2.171a3.001 3.001 0 011.994 2.653L17 11.5v5h-2v-5a1 1 0 00-.883-.993L14 10.5H4.133l-.04.016a1.684 1.684 0 00-.443.277c-.37.316-.608.786-.645 1.505L3 12.5v2H1v-2c0-1.423.504-2.504 1.35-3.228a3.433 3.433 0 011.307-.717l.137-.034L4 8.5h9v-2h2z" />
        <path d="M8 15.5a1 1 0 100 2 1 1 0 000-2zm-4 0a1 1 0 100 2 1 1 0 000-2zm8 0a1 1 0 100 2 1 1 0 000-2zm-3-12a2 2 0 011.995 1.85L11 5.5v4H9v-4H5v4H3v-4a2 2 0 011.85-1.995L5 3.5h4zm15 15c-1.657 0-3-1-3-6a1 1 0 00-2 0v7a1 1 0 001 1h3a1 1 0 001-1v-1z" />
      </g>
    </svg>
  )
}

export default SvgComponent
