import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18 3l-1 2-9 3H4c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h2v5h2v-5l9 3 1 2h2v-7.277c.596-.347 1-.984 1-1.723s-.404-1.376-1-1.723V3zm0 3.775v10.45l-.367-.123L10 14.559V9.44l7.633-2.543.367-.123zM4 10h4v4H4z" />
    </svg>
  )
}

export default SvgComponent
