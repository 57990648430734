import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={12}
      viewBox="0 0 15 15"
      width={12}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="currentColor">
        <path d="M2.2 14.3c-.4 0-.75-.15-1.05-.45-.6-.6-.6-1.55 0-2.1l10.6-10.6c.6-.6 1.55-.6 2.1 0 .6.6.6 1.55 0 2.1l-10.6 10.6c-.3.3-.65.45-1.05.45z" />
        <path d="M12.8 14.3c-.4 0-.75-.15-1.05-.45L1.15 3.25c-.6-.6-.6-1.55 0-2.1.6-.6 1.55-.6 2.1 0l10.6 10.6c.6.6.6 1.55 0 2.1-.3.3-.65.45-1.05.45z" />
      </g>
    </svg>
  )
}

export default SvgComponent
