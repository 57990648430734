import {h, Fragment} from 'preact'
import {useState, useEffect} from 'preact/hooks'
import axios from 'axios'
import cn from 'classnames'
import {StylesProvider} from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'

import './App.scss'
import {Row, Col} from './common/grid'
import Login from './Login'
import User from './User'
import Video from './Video'
import Employment from './Employment'
import Education from './Education'
import Skills from './Skills'
import Goals from './Goals'
import Hobby from './Hobby'
import Language from './Language'
import Footer from './Footer'
import Licence from './Licence'
import Gears from './Gears'
import VehicleTypes from './VehicleTypes'
import {IS_PDF, UUID} from '../utils/constants'

const autoHideDuration = 8000

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const Block = ({
  type,
  values,
  profile,
  account,
  videoExists,
  setProfile,
  onSnackbarOpen,
}) => {
  const name = `${profile.firstName} ${profile.lastName}`
  switch (type) {
    case 'PERSONAL_INFO':
      return (
        <User
          account={account}
          profile={profile}
          photo={profile.photo}
          name={name}
          email={profile.email}
          phone={profile.phone}
          city={profile.city}
          setProfile={setProfile}
          onSnackbarOpen={onSnackbarOpen}
        />
      )
    case 'EMPLOYMENT':
      return <Employment {...values} />
    case 'EDUCATION':
      return <Education {...values} />
    case 'LANGUAGE':
      return <Language {...values} />
    case 'HOBBY':
      return <Hobby {...values} />
    case 'PERSONAL_VIDEO':
      return <Video videoExists={videoExists} url={profile.video} />
    case 'HARD_SKILLS':
      return <Skills {...values} title="Hard skills" />
    case 'SOFT_SKILLS':
      return <Skills {...values} title="Soft skills" />
    case 'GOALS':
      return <Goals {...values} />
    case 'QUOTE':
      return <Footer {...values} />
    case 'DRIVER_LICENCE':
      return <Licence {...values} />
    case 'DRIVING_DISCIPLINES':
      return <Skills driver {...values} title="Driving disciplines" />
    case 'MACHINE_OPERATOR_DISCIPLINES':
      return <Skills driver {...values} title="Machine operator disciplines" />
    case 'VEHICLE_GEARBOXES':
      return <Gears {...values} />
    case 'VEHICLE_TYPE':
      return <VehicleTypes {...values} />
    default:
      return null
  }
}

export default function App() {
  const [profile, setProfile] = useState(null)
  const [account, setAccount] = useState(null)
  const [loading, setLoading] = useState(true)
  const [snackbar, setSnackbar] = useState({open: false, message: ''})

  useEffect(async () => {
    try {
      const {data} = await axios.get(`/cv/${UUID}`)
      setProfile(data.data)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }, [])

  const handleSnackbarOpen = IS_PDF
    ? undefined
    : (response) => {
        let errorMessage = 'Server connection error.'
        if (response) {
          if (response.data.errors) {
            errorMessage = Object.values(response.data.errors).flat().join('\n')
          } else {
            errorMessage = response.data.message
          }
        }
        setSnackbar({message: errorMessage, open: true})
      }

  const handleSnackbarClose = IS_PDF
    ? undefined
    : () => setSnackbar({...snackbar, open: false})

  return (
    <StylesProvider injectFirst>
      <main class={cn('App', {App_pdf: IS_PDF})}>
        {loading ? (
          <div class="App-Loader">
            <CircularProgress />
          </div>
        ) : profile ? (
          <>
            <div class={IS_PDF ? undefined : 'FadeIn'}>
              <div class="App-Decoration" />
              <div class="App-Background" />
              <div class="App-Container">
                {!IS_PDF && (
                  <Row center="xs">
                    <Col xs={12} lg={10}>
                      <Login
                        account={account}
                        setAccount={setAccount}
                        onSnackbarOpen={handleSnackbarOpen}
                      />
                    </Col>
                  </Row>
                )}
                <Row className="App-Row" center="xs">
                  <Col xs={12} md={6} lg={5}>
                    {profile.blocks[0].map((block) => (
                      <Block
                        key={block.type}
                        {...block}
                        profile={profile}
                        account={account}
                        setProfile={setProfile}
                        onSnackbarOpen={handleSnackbarOpen}
                      />
                    ))}
                  </Col>
                  <Col xs={12} md={6} lg={5}>
                    {profile.blocks[1].map((block) => (
                      <Block
                        key={block.type}
                        {...block}
                        profile={profile}
                        videoExists={profile.videoExists}
                      />
                    ))}
                  </Col>
                </Row>
                {profile.blocks[2].map((block) => (
                  <Block key={block.type} {...block} profile={profile} />
                ))}
              </div>
            </div>
            {!IS_PDF && (
              <Snackbar
                anchorOrigin={anchorOrigin}
                open={snackbar.open}
                autoHideDuration={autoHideDuration}
                message={snackbar.message}
                onClose={handleSnackbarClose}
              />
            )}
          </>
        ) : (
          <p class="App-Error FadeIn">Profile was not found.</p>
        )}
      </main>
    </StylesProvider>
  )
}
