import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.786 3a1 1 0 01.993.883l.007.117-.001 4.714h3.572a1 1 0 01.993.884l.007.116v2.618a4.429 4.429 0 11-3.03 5.24h-3.85a3.858 3.858 0 01-7.668 0H1.5a1 1 0 01-.993-.884L.5 16.571v-5.714a1 1 0 01.576-.905l.108-.044L4.06 8.95l1.031-5.146a1 1 0 01.863-.797L6.07 3h5.715zM6.643 15.286a1.857 1.857 0 100 3.714 1.857 1.857 0 000-3.714zm12-1.143a2.43 2.43 0 00-2.214 1.428h2.214a1 1 0 01.116 1.994l-.116.006h-2.214a2.429 2.429 0 102.214-3.428zm-3.286-3.429H5.088l-2.588.863v3.994l.62-.001a3.858 3.858 0 017.046.001h4.162a4.417 4.417 0 011.028-1.968l.001-2.889zM10.785 5H6.891l-.743 3.714h4.637V5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
