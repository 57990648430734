import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={18}
      viewBox="0 0 20 18"
      width={20}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 0a2 2 0 100 4 2 2 0 000-4zm0 5c-2.06 0-3.77.812-3.963 2H0v2h1.219l.623 2.486A2.01 2.01 0 003.782 13H6v5h2v-5h4v5h2v-5h2.219a2.01 2.01 0 001.94-1.514L18.78 9H20V7h-6.037C13.77 5.812 12.059 5 10 5zM3.281 9H16.72l-.5 2H3.78z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
