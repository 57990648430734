import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M11.926 2v7.166l-1-.166V3h-2v11.22l-1.719-1.185L7.131 13a2.438 2.438 0 00-2.783.523L3 14.94l5.012 6.147.035.035a3 3 0 002.121.879h6.758c1.645 0 3-1.355 3-3V5h-2v5.166l-1-.166V3h-2v6.666l-1-.166V2h-2zm-1 9.027l7 1.168V19c0 .565-.435 1-1 1h-6.758a.994.994 0 01-.695-.287L5.664 15.04l.13-.137a.42.42 0 01.474-.088l4.658 3.213v-7.002zM13.168 14c-.769 0-1.242.697-1.242 1.43 0 1.059 2.5 2.855 2.5 2.855s2.5-1.79 2.5-2.855c0-.733-.466-1.43-1.235-1.43-.684 0-1.01.679-1.265 1-.256-.321-.545-1-1.258-1z" />
    </svg>
  )
}

export default SvgComponent
