import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 4a2 2 0 00-2 2v10a2 2 0 002 2H0v2h24v-2h-4a2 2 0 002-2V5.994A1.995 1.995 0 0020 4zm0 2h16l.002 10H4zm6 2v6l5-3z" />
    </svg>
  )
}

export default SvgComponent
