import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M10.156 3L8.32 5H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2h-2l-1.844-2h-6zm.88 2h4.243l1.844 2H20v12H4V7h5.2l1.835-2zM6 8a1 1 0 100 2 1 1 0 000-2zm7 0a5 5 0 10.001 10.001A5 5 0 0013 8zm0 2a3 3 0 110 6 3 3 0 010-6z" />
    </svg>
  )
}

export default SvgComponent
