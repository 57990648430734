import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={118}
      viewBox="0 0 118 118"
      width={118}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M59 0c32.585 0 59 26.415 59 59s-26.415 59-59 59S0 91.585 0 59 26.415 0 59 0zm-9 39.324a6 6 0 00-6 6v28.352a6 6 0 008.97 5.213l24.88-14.176a6 6 0 000-10.426L52.97 40.11a6 6 0 00-2.97-.787z"
        fill="currentColor"
        fillRule="evenodd"
        opacity={0.9}
      />
    </svg>
  )
}

export default SvgComponent
