import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={18}
      viewBox="0 0 24 18"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.273 0a1 1 0 00-.906.576L3.18 5.252 1.555 4.168.445 5.832l1.88 1.252-.231.492A1 1 0 002 8v9a1 1 0 001 1h2l-.006-2h14.012L19 18h2c.55 0 1-.45 1-1V8a1 1 0 00-.08-.395l-.229-.53 1.864-1.243-1.11-1.664-1.554 1.037-1.971-4.6A1 1 0 0018 0zm.637 2h10.43l1.715 4H5.039zM4.104 8h15.808l.088.207V14H4V8.223zM7 10a1 1 0 100 2 1 1 0 000-2zm10 0a1 1 0 100 2 1 1 0 000-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
