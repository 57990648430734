import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.027 3c-2.705-.018-4.898 2.117-5.011 4.79L3.23 18.363a1 1 0 00.063 1.345L5.285 21.7c.365.365.949.394 1.346.065l10.598-8.79c2.663-.125 4.785-2.315 4.765-5.013C21.974 5.29 19.7 3.018 17.027 3zm-.033 2c1.654 0 3 1.346 3 3a2.988 2.988 0 01-2.86 2.979l-.667.031-.028.021-2.466-2.466.011-.016.03-.674A2.984 2.984 0 0116.994 5zm-4.305 5.11l2.206 2.205-8.84 7.33-.703-.705z" />
    </svg>
  )
}

export default SvgComponent
