import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={27}
      viewBox="0 0 21 27"
      width={21}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path
          d="M.471 22.908l3.9 3.381c.45.39 1.024.403 1.283.028a.754.754 0 00.126-.433V14.023a3.554 3.554 0 00-1.217-2.677L.376 7.69A.226.226 0 000 7.86v14.123c.004.152.045.317.126.48.082.167.201.32.344.445z"
          opacity={0.5}
        />
        <path d="M1.254 5.43L19.45.147c.685-.199 1.24.195 1.24.88v13.027c0 .685-.555 1.402-1.24 1.6L.014 21.673V7.03c0-.685.555-1.402 1.24-1.6z" />
      </g>
    </svg>
  )
}

export default SvgComponent
