import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M11.405 2.5a1 1 0 01.993.883l.007.117v9.24h-2l-.001-8.24H5.63l.2.4a1 1 0 01.1.332l.006.116v4.62h-2V5.584l-.818-1.638a1 1 0 01.78-1.44l.114-.006h7.392zM22.111 17.787a1.848 1.848 0 110-3.695 1.848 1.848 0 010 3.695z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <path
          d="M18.754 11.993s2.162-.043 3.413 3.393"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinejoin="round"
        />
        <path d="M5.366 10.891H2.575l2.079 5.544H16.19l.554-2.772H7.709a.924.924 0 01-.7-.32l-.069-.091-1.574-2.36zm11.582-.924h-5.543V8.12h6.467c.474 0 .864.356.918.816l.006.107v3.696l-.018.181-.924 4.62a.924.924 0 01-.797.736l-.109.007H4.013a.924.924 0 01-.818-.495l-.047-.105-2.772-7.391a.924.924 0 01.76-1.243l.106-.006h4.62c.27 0 .524.119.698.32l.07.092 1.573 2.36h8.745V9.967z" />
        <path
          d="M6.47 14.125a3.234 3.234 0 110 6.467 3.234 3.234 0 010-6.467zm0 1.848a1.386 1.386 0 100 2.772 1.386 1.386 0 000-2.772zm9.974-1.848a3.234 3.234 0 110 6.467 3.234 3.234 0 010-6.467zm0 1.848a1.386 1.386 0 100 2.772 1.386 1.386 0 000-2.772z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M18.87 8.128v4.611l-.025.218-.548 2.46-1.952-.435.524-2.353v-2.502h-5.467v-2z"
        />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M0 9.207h6.748l1.619 2.532h9.502v2H7.292l-1.619-2.532H2.821l2.147 5.971-1.733.56zM14.128 16.359v2H8.892v-2z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
