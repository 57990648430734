import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.998 6C17.088 6 18 6.848 18 7.963V10h6.67c.735 0 1.493.57 1.695 1.278l1.54 5.388h1.428c.736 0 1.334.599 1.334 1.338v6.659c0 .738-.601 1.337-1.334 1.337h-1.56a4.002 4.002 0 01-7.545 0h-7.123a4.002 4.002 0 01-7.547-.008h-2.89a1.333 1.333 0 110-2.667h2.896a4.002 4.002 0 017.542.008h2.227V8.67L2.667 8.667a1.333 1.333 0 010-2.667zM24 23.333A1.333 1.333 0 1024 26a1.333 1.333 0 000-2.667zm-14.667 0a1.333 1.333 0 100 2.667 1.333 1.333 0 000-2.667zM28 19.324l-.518.002a1.317 1.317 0 01-.143.007H19l-.999.002.007 3.998h2.22a4.002 4.002 0 017.545 0H28v-4.009zm-3.993-6.657L18 12.666v4h7.143l-1.135-4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgComponent
