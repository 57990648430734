import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
        <filter id="prefix__b">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        filter="url(#prefix__a)"
        transform="translate(-170 -681)"
        fill="none"
        fillRule="evenodd"
      >
        <g filter="url(#prefix__b)" transform="translate(170 681)">
          <path
            fill="#000"
            fillRule="nonzero"
            d="M12 1a2 2 0 00-1.994 1.879L5.102 5.332A2 2 0 103 8.73v6.541a2 2 0 102.102 3.397l4.904 2.451A1.999 1.999 0 0012 23a2 2 0 001.994-1.879l4.904-2.453A2 2 0 1021 15.27V8.729a2 2 0 10-2.102-3.397l-4.904-2.451A1.999 1.999 0 0012 1zm-1.102 3.668a2 2 0 002.204 0l4.904 2.451c.04.67.413 1.275.994 1.611v6.541a2 2 0 00-.994 1.608l-4.904 2.453a2 2 0 00-2.204 0l-4.904-2.451A1.998 1.998 0 005 15.27V8.729a2 2 0 00.994-1.608l4.904-2.453zM12 8a2 2 0 10-.001 3.999A2 2 0 0012 8zm0 5c-1.335 0-3 .72-3 2.05V16h6v-.95c0-1.33-1.665-2.05-3-2.05z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
