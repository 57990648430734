import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.338 2.25l.097.005h6.21a2 2 0 012 2v3.047l-.671.267c-.38.156-.743.384-1.008.658.73.26 1.262.931 1.32 1.733l.006.15v3.279l-.55 1.953a3.5 3.5 0 11-5.243 4.093h-4.21a3.501 3.501 0 01-6.696-.36 1.008 1.008 0 01-.132-.208l-.044-.108-2.35-6.861a2 2 0 01.42-2.001l-.01.03c.085-.214.163-.656.08-1.193-.09-.59-.364-1.152-.885-1.676a2 2 0 111.776-1.013A4.864 4.864 0 015.595 9.25l.744-.001V5.487l-.895-1.79a1 1 0 01.78-1.44l.115-.007h8zm-5.342 14.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm10.796 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-11.968-5.5H4.96l1.552 4.534a3.501 3.501 0 015.888 1.65h3.988a3.501 3.501 0 013.404-2.684l.037.001.142-.502-9.988.001-2.16-3zm12.468-1.14h-4.647v2.139h4.647v-2.14zm-6.954-5.86H7.955l.277.553a1 1 0 01.099.331l.007.116v3.999l.512.001 2.158 3 2.33-.001v-8zm7.308.005h-5.001v3.819l3.079.023.01-.028c.21-.573.59-1.073 1.09-1.5l.203-.163c.203-.156.41-.29.618-.408V4.255z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
