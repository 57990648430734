import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.622 3.647c.478 0 .872.36.926.823l.006.109v4.393h2.996c.43 0 .76.278.882.631l2.562 2.895a4.127 4.127 0 11-1.46 1.164l-1.21-1.369v4.465c0 .478-.36.872-.823.926l-.109.006h-1.009a.932.932 0 01-.109-1.858l.11-.006h.075v-2.672l-2.704 3.965a3.133 3.133 0 01-2.94 2.836l-.178.005a3.122 3.122 0 01-2.201-.903 3.132 3.132 0 01-5.287-2.76l-.045-.002V10.97c0-.325.169-.62.435-.788V5.634h1.864V9.53l1.02-.34.96-4.795c.08-.4.408-.696.804-.743l.11-.006h5.325zm9.146 10.315a2.263 2.263 0 100 4.527 2.263 2.263 0 000-4.527zM7.637 15.56a1.268 1.268 0 100 2.536 1.268 1.268 0 000-2.536zm-4.4 0a1.268 1.268 0 100 2.536 1.268 1.268 0 000-2.536zm9.548-4.724H4.383l-2.415.805v2.323a3.121 3.121 0 011.268-.268 3.12 3.12 0 012.201.904 3.114 3.114 0 012.2-.904c.973 0 1.843.444 2.418 1.141l2.73-4zM9.69 5.511H6.06l-.692 3.461H9.69V5.511z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
