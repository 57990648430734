import {h} from 'preact'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './CircleIcon.scss'

CircleIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
}

export default function CircleIcon({className, icon}) {
  return <div class={cn('CircleIcon', className)}>{icon}</div>
}
