import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M7.245 15.828a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm11 0a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm-12.5 2h-5m9 0h6"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinejoin="round"
        />
        <path
          d="M15.745 17.828h-2v-9a1 1 0 01.884-.993l.116-.007h3.963a3 3 0 012.448 1.264l.107.162 2.038 3.308c.254.414.404.882.437 1.365l.007.207v3.694a1 1 0 01-.883.993l-.117.007h-2v-2h1v-2.694c0-.139-.029-.276-.084-.402l-.064-.122-2.037-3.307a1 1 0 00-.723-.467l-.129-.008h-2.963v8zM9.54 3.293l1.414 1.414L9.8 5.861l3.154 3.155a1 1 0 01.083 1.32l-.083.094-7.13 7.13a1 1 0 01-1.414 0L.547 13.7a1 1 0 010-1.415L4.313 8.52 9.54 3.293zM8.385 7.276L5.727 9.933v-.001l-3.059 3.06 2.448 2.448 5.716-5.717-2.447-2.447z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
