import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={16}
      viewBox="0 0 24 16"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 0C2.897 0 2 .897 2 2v10c0 1.103.897 2 2 2H0v2h24v-2h-4c1.103 0 2-.897 2-2V2c0-1.103-.897-2-2-2zm0 2h16l.002 10H4z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
