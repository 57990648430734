import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.94 2.19c.994 0 1.975.487 2.47 1.233l.092.152 4.066 6.475c.203.368.343.747.427 1.14h1.398V5.555l-1.421-1.73 1.545-1.27 1.876 2.284v8.351H13.25a1 1 0 01-.498-.133l-.11-.072-2.345-1.795h-.371a5.988 5.988 0 012.106 5.501h4.033a4.002 4.002 0 017.84 1.119 4 4 0 01-7.902.882l-4.643-.001A6 6 0 116.095 9.81V4.107c0-1.073.795-1.844 1.856-1.912l.153-.005h3.835zm-5.845 9.62a4 4 0 100 8 4 4 0 000-8zm13.81 4a2 2 0 100 4 2 2 0 000-4zM8.095 4.188V9.19h2.54a1 1 0 01.499.134l.109.072 2.345 1.794h3.318l-.06-.126-4.067-6.474c-.102-.186-.398-.357-.719-.393l-.12-.007H8.103l-.009-.002z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
