if (process.env.NODE_ENV === 'development') {
  require('preact/debug')
}
import {h, render} from 'preact'
import axios from 'axios'

import './styles/index.scss'
import App from './components/App'

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-TLD2DPF',
}

window.pageLoaded = false

TagManager.initialize(tagManagerArgs)

axios.defaults.baseURL = process.env.REACT_APP_API_URL

render(<App />, document.getElementById('app'))
