import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="currentColor"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <path d="M7 14.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm10 0a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" />
        <path d="M19.5 16.5h2v-6l-3.113-5.049a1.999 1.999 0 00-1.702-.951H4.5a2 2 0 00-2 2v10h2m4-12v6m6-6v6m6.5 0H2.5m7 6h6" />
      </g>
    </svg>
  )
}

export default SvgComponent
