import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M17.849 2h-11.7c-1.136 0-2.066.943-2.066 2.095v15.81c0 1.152.93 2.095 2.067 2.095h11.7c1.136 0 2.066-.943 2.066-2.095V4.095c0-1.152-.93-2.095-2.067-2.095zm-11.7 1.818h11.7c.146 0 .273.129.273.277v15.81a.282.282 0 01-.273.277h-11.7a.281.281 0 01-.272-.277V4.095c0-.148.127-.277.273-.277z" />
        <path d="M15.024 12L12 7.766 8.975 12 12 16.234z" />
      </g>
    </svg>
  )
}

export default SvgComponent
