import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={43} height={17} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="currentColor">
          <path
            d="M38 10.8a3 3 0 100 6 3 3 0 000-6zm0 4.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25zM21.913 10.8a3 3 0 100 6 3 3 0 000-6zm-6 0a3 3 0 100 6 3 3 0 000-6zm6 4.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25a1.251 1.251 0 010 2.5zm-6 0c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25a1.251 1.251 0 010 2.5z"
            fillRule="nonzero"
          />
          <path
            fillRule="nonzero"
            d="M40 11.8h2v2h-2zM26.957 11.8h8.853v2h-8.853z"
          />
          <path d="M23.824 11.8h4v2h-4zM.58 11.8h14v2h-14zM17.875 11.8h1.907v2h-1.907z" />
        </g>
        <path
          stroke="currentColor"
          strokeWidth={2}
          d="M30 2.764h6.213L38 7.696h4V13.8M25.824 12.8V1H30v8.8"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
