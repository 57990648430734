import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        filter="url(#prefix__a)"
        transform="translate(-170 -1305)"
        fill="none"
        fillRule="evenodd"
      >
        <path
          fill="#000"
          fillRule="nonzero"
          d="M178.874 1307.519c.993 0 1.972.485 2.465 1.23l.092.152 4.059 6.462c.326.592.488 1.209.52 1.876l.007.252v1.006h-.002v2.047c.576.587.946 1.377.994 2.253l.005.191a3.493 3.493 0 01-6.65 1.497h-1.67a3.496 3.496 0 01-3.158 1.996 3.493 3.493 0 01-3.458-3.993l-2.078.002v-7.985h3.041v-5.073c0-1.072.793-1.84 1.852-1.909l.153-.004h3.828zm-3.338 13.971a1.497 1.497 0 100 2.993 1.497 1.497 0 000-2.993zm7.985 0a1.498 1.498 0 100 2.995 1.498 1.498 0 000-2.995zm6.487-13.97v14.97H194v1.996h-5.988v-16.966h1.996zm-12.774 8.98h-2.197v3.03a3.493 3.493 0 013.957 2.957h1.069a3.494 3.494 0 013.956-2.958v-1.033h-3.838a.998.998 0 01-.498-.133l-.108-.072-2.341-1.791zm-4.194 3.993v-3.993h-1.044v3.992h1.045zm1.996-10.98v4.992h2.535c.176 0 .347.046.498.133l.109.072 2.34 1.791h3.312l-.06-.126-4.059-6.462c-.102-.185-.397-.356-.717-.392l-.121-.006h-3.827l-.009-.002z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
