import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={18}
      viewBox="0 0 18 18"
      width={18}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 0v3H0v2h3v3h2V5h3V3H5V0zm7 3v2h8V3zm-8.293 7.293L.293 11.707 2.586 14 .293 16.293l1.414 1.414L4 15.414l2.293 2.293 1.414-1.414L5.414 14l2.293-2.293-1.414-1.414L4 12.586zM10 11v2h8v-2zm0 4v2h8v-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
