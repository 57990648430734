import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={12}
      viewBox="0 0 12 12"
      width={12}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.4 0v2.095L.595 3.148A1.205 1.205 0 000 4.185V10.2c0 .662.538 1.2 1.2 1.2h9.6c.662 0 1.2-.538 1.2-1.2V4.185c0-.426-.228-.823-.595-1.037L9.6 2.095V0zm1.2 1.2h4.8v4.4L6 7.093 3.6 5.6zM2.4 3.484v1.369l-1.136-.707zm7.2.001l1.136.662-1.136.707zM1.2 5.52L6 8.507l4.8-2.987.001 4.68H1.2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
