import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9 3a2 2 0 00-2 2v10.184A2.966 2.966 0 006 15a3 3 0 103 3V9h9v6.184A2.966 2.966 0 0017 15a3 3 0 103 3V3zm0 2h9v2H9zM6 17c.551 0 1 .449 1 1s-.449 1-1 1-1-.449-1-1 .449-1 1-1zm11 0c.551 0 1 .449 1 1s-.449 1-1 1-1-.449-1-1 .449-1 1-1z" />
    </svg>
  )
}

export default SvgComponent
