import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={35}
      viewBox="0 0 35 35"
      width={35}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M13.612 18.416H1.107a1.107 1.107 0 010-2.215h12.505a1.107 1.107 0 110 2.215M33.507 18.416H21.002a1.107 1.107 0 010-2.215h12.505a1.107 1.107 0 110 2.215M17.307 34.615a1.107 1.107 0 01-1.107-1.108V21.002a1.107 1.107 0 112.215 0v12.505c0 .612-.496 1.108-1.108 1.108M17.307 14.72a1.107 1.107 0 01-1.107-1.108V1.107a1.107 1.107 0 112.215 0v12.505c0 .612-.496 1.108-1.108 1.108" />
        <path d="M17.307 2.225c8.317 0 15.083 6.767 15.083 15.083 0 8.317-6.766 15.084-15.083 15.084S2.224 25.625 2.224 17.308c0-8.316 6.766-15.083 15.083-15.083zm0 2.215c-7.096 0-12.868 5.773-12.868 12.868 0 7.096 5.772 12.869 12.868 12.869s12.869-5.773 12.869-12.869c0-7.095-5.773-12.868-12.869-12.868z" />
        <path d="M25.124 18.416a1.107 1.107 0 01-1.108-1.108 6.717 6.717 0 00-6.709-6.708c-2.74 0-5.18 1.64-6.215 4.178-.23.566-.875.84-1.444.607a1.106 1.106 0 01-.607-1.444 8.893 8.893 0 018.266-5.557c4.92 0 8.924 4.004 8.924 8.924 0 .612-.496 1.108-1.107 1.108M17.308 26.232c-4.92 0-8.924-4.003-8.924-8.924a1.107 1.107 0 012.216 0c0 3.7 3.01 6.71 6.708 6.71 2.742 0 5.18-1.64 6.216-4.179a1.108 1.108 0 012.05.837 8.894 8.894 0 01-8.266 5.556" />
        <path d="M17.308 18.806c-.396 0-.781-.155-1.062-.437a1.53 1.53 0 01-.438-1.063c0-.395.162-.779.438-1.061.557-.552 1.566-.558 2.124 0 .276.282.438.666.438 1.061 0 .397-.162.781-.438 1.063a1.504 1.504 0 01-1.062.437" />
      </g>
    </svg>
  )
}

export default SvgComponent
