import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path
          d="M18 15.5a3 3 0 100 6 3 3 0 000-6zm0 4.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25zM5 15.5a3 3 0 100 6 3 3 0 000-6zm0 4.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25zm9-6.25h8l-1-2h-7v2z"
          fillRule="nonzero"
        />
        <path fillRule="nonzero" d="M20 16.5h2v2h-2z" />
        <path
          d="M20.276 8.053a.998.998 0 00-.894-.553H14v11h2v-9h2.764L21 13.972V18.5h2v-5l-2.724-5.447zM9.259 7.389l-.001 2.028 1.72-1.075.636 1.017-1.824 1.14 1.824 1.142-.636 1.017-1.72-1.076v2.03h-1.2v-2.03l-1.72 1.076-.635-1.017L7.527 10.5 5.703 9.359l.636-1.017 1.719 1.074V7.389h1.2z"
          fillRule="nonzero"
        />
        <g transform="translate(3.29 5.697)">
          <circle cx={5.313} cy={1.28} r={1.28} />
          <circle
            transform="rotate(60 8.345 3.03)"
            cx={8.345}
            cy={3.031}
            r={1.28}
          />
          <circle
            transform="rotate(120 8.345 6.533)"
            cx={8.345}
            cy={6.533}
            r={1.28}
          />
          <circle
            transform="rotate(180 5.313 8.284)"
            cx={5.313}
            cy={8.284}
            r={1.28}
          />
          <circle
            transform="rotate(-120 2.28 6.533)"
            cx={2.28}
            cy={6.533}
            r={1.28}
          />
          <circle
            transform="rotate(-60 2.28 3.03)"
            cx={2.28}
            cy={3.031}
            r={1.28}
          />
        </g>
        <path
          d="M3.503 2.5h7.943l4.514 3.952V16.5c0 1.085-.907 1.924-1.996 1.995l-.15.005H6.918v-2h6.896l.056-.003.07-.012c.006 0 .012 0 .015.002l.005.013V7.359L10.694 4.5H3.503l-.056.003-.07.012c-.006 0-.012 0-.015-.002L3.357 4.5v12c0-.014.006-.017.02-.015l.07.012.056.003v2c-1.105 0-2.06-.792-2.14-1.854l-.006-.146v-12c0-1.085.907-1.924 1.996-1.995l.15-.005z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
