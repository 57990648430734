import {h, Fragment} from 'preact'
import PropTypes from 'prop-types'

import './Licence.scss'
import LicenceOneIcon from './icons/licence-one'
import LicenceTwoIcon from './icons/licence-two'
import LicenceThreeIcon from './icons/licence-three'
import LicenceFourIcon from './icons/licence-four'
import LicenceFiveIcon from './icons/licence-five'
import LicenceSixIcon from './icons/licence-six'
import Block from './common/Block'

Licence.propTypes = {
  classes: PropTypes.array,
  classesData: PropTypes.array,
  endorsements: PropTypes.array,
  endorsementsData: PropTypes.array,
}

const icons = [
  <LicenceOneIcon class="Licence-Icon" />,
  <LicenceTwoIcon class="Licence-Icon" />,
  <LicenceThreeIcon class="Licence-Icon" />,
  <LicenceFourIcon class="Licence-Icon" />,
  <LicenceFiveIcon class="Licence-Icon" />,
  <LicenceSixIcon class="Licence-Icon" />,
]

const Class = ({number, label}) => {
  const labelWords = label.replace('- ', '').split(' ')
  return (
    <li class="Licence-Item">
      {icons[number - 1]}
      <h3 class="Licence-Title">Class {number}</h3>
      <p class="Licence-Label">
        {labelWords.map((word, index) => (
          <Fragment>
            {word}
            {index !== labelWords.length - 1 && <br />}
          </Fragment>
        ))}
      </p>
    </li>
  )
}

export default function Licence({
  classes,
  classesData,
  endorsements,
  endorsementsData,
}) {
  if (!classes && !endorsements) return null

  return (
    <Block class="Licence" title="Licence">
      {classes && (
        <ul class="Licence-List">
          {classes.map((item) => (
            <Class
              key={item.value}
              number={item.value}
              label={classesData[item.value - 1].description}
            />
          ))}
        </ul>
      )}
      {endorsements && (
        <Fragment>
          <p class="Licence-Types">
            {endorsements.map((endorsement) => endorsement.value).join(' ')} –
            endorsement{endorsements.length > 1 ? 's' : ''}
          </p>
          <p class="Licence-Text">
            {endorsements
              .map(
                (endorsement) =>
                  endorsementsData.find(
                    (item) => item.value === endorsement.value
                  ).description
              )
              .filter((item) => item)
              .join(', ')
              .replace(/- /g, '')}
          </p>
        </Fragment>
      )}
    </Block>
  )
}
