import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={20}
      viewBox="0 0 18 20"
      width={18}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 0a2 2 0 100 4 2 2 0 000-4zM2 5a2 2 0 00-2 2v7h1v6h2v-6h2v6h2V9H5v3H2V7h9V5zm11 0v2h5V5zM9 9v2h2V9zm4 0v2h5V9zm-4 4v2h2v-2zm4 0v2h5v-2zm-4 4v2h2v-2zm4 0v2h5v-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
