import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M4 3v4.03l-2 6.007V15h1v6h10v-6h6v6h2v-6h1v-1.963L20 7.03V3H4zm2 2h12v2H6V5zm-.549 4H18.55l1.332 4H4.12l1.33-4zM5 15h6v4H5v-4z" />
    </svg>
  )
}

export default SvgComponent
