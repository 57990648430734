import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.8 1.875c-.984 0-1.8.816-1.8 1.8v11.7c0 .984.816 1.8 1.8 1.8h14.4c.984 0 1.8-.816 1.8-1.8v-11.7c0-.984-.816-1.8-1.8-1.8h-1.273l-.9.9H6.973l-.9-.9zm0 1.8h.527l.9.9h11.546l.263-.264.637-.636h.527v11.7H4.8zm2.7 2.7v7.2h1.8v-7.2zm3.6 0v7.2h1.8v-7.2zm3.6 0v7.2h1.8v-7.2zM3.9 18.525v3.6h3.6v-1.8h2.7v1.8h3.6v-1.8h2.7v1.8h3.6v-3.6z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent
