import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M11 2v2H3v6h8v2H5l-3 3 3 3h6v4h2v-4h8v-6h-8v-2h6l3-3-3-3h-6V2h-2zM5 6h13.172l1 1-1 1H5V6zm.828 8H19v2H5.828l-1-1 1-1z" />
    </svg>
  )
}

export default SvgComponent
