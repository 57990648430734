import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={18}
      viewBox="0 0 18 18"
      width={18}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.75 0a1 1 0 00-.863.496l-1.75 3A.999.999 0 000 4v12c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2V4a.999.999 0 00-.137-.504l-1.75-3A1 1 0 0015.25 0zm.574 2h11.352l1.166 2H2.158zM2 6h14v10H2zm4 2v2h6V8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
