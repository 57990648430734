import {h} from 'preact'
import PropTypes from 'prop-types'

import './Donut.scss'

Donut.propTypes = {
  value: PropTypes.number.isRequired,
}

export default function Donut({value}) {
  return (
    <svg class="Donut" viewBox="0 0 36 36" strokeLinecap="round">
      <defs>
        <linearGradient id="donut" class="Donut-Gradient">
          <stop offset="0" />
        </linearGradient>
      </defs>
      <path
        class="Donut-Circle"
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        class="Donut-Arc"
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
        stroke={`url(#donut)`}
        style={{strokeDasharray: `${20 * value} 100`}}
      />
    </svg>
  )
}
