import {h} from 'preact'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './Skills.scss'
import ComputerIcon from './icons/computer'
import BoxIcon from './icons/box'
import PenIcon from './icons/pen'
import MathIcon from './icons/math'
import VehicleIcon from './icons/vehicle'
import ManWithMoneyIcon from './icons/man-with-money'
import MeetingIcon from './icons/meeting'
import CollaborationIcon from './icons/collaboration'
import ManagementIcon from './icons/management'
import LecturerIcon from './icons/lecturer'
import StreetViewIcon from './icons/street-view'
import RomanceIcon from './icons/romance'
import Management2Icon from './icons/management_s'
import AnalyticalIcon from './icons/analytical'
import DesignIcon from './icons/design'
import MarketingIcon from './icons/marketing'

import DrivingBulkIcon from './icons/driving-bulk'
import DrivingTipperIcon from './icons/driving-tipper'
import DrivingCartageIcon from './icons/driving-cartage'
import DrivingCourierIcon from './icons/driving-courier'
import DrivingFridgeIcon from './icons/driving-fridge'
import DrivingFurnitureIcon from './icons/driving-furniture'
import DrivingHaulIcon from './icons/driving-haul'
import DrivingHiabIcon from './icons/driving-hiab'
import DrivingLoggingIcon from './icons/driving-logging'
import DrivingOverIcon from './icons/driving-over'
import DrivingPassengerIcon from './icons/driving-passenger'
import DrivingRecoveryIcon from './icons/driving-recovery'
import DrivingRubbishIcon from './icons/driving-rubbish'
import DrivingSmallIcon from './icons/driving-small'
import DrivingTankerIcon from './icons/driving-tanker'
import DrivingConcreteIcon from './icons/driving-concrete'
import DrivingContainerIcon from './icons/driving-container'

import MachineAgriculturalIcon from './icons/machine-agricultural'
import MachineBalerIcon from './icons/machine-baler'
import MachineChopperIcon from './icons/machine-chopper'
import MachineCombinerIcon from './icons/machine-combiner'
import MachineContainerIcon from './icons/container-loading-equipment'
import MachineForkliftIcon from './icons/forklifts'
import MachineForagerIcon from './icons/machine-forager'
import MachineLoaderIcon from './icons/machine-loader'
import MachineRollerIcon from './icons/machine-roller'
import MachineSkidderIcon from './icons/machine-skidder'
import MachineDiggerIcon from './icons/machine-digger'
import MachineDozerIcon from './icons/machine-dozer'
import MachineGraderIcon from './icons/machine-grader'
import MachineRoadingIcon from './icons/machine-roading'
import MachineMiningIcon from './icons/machine-mining'

import Block from './common/Block'
import Quote from './common/Quote'

Skills.propTypes = {
  driver: PropTypes.bool,
  title: PropTypes.string.isRequired,
  types: PropTypes.array,
  typesData: PropTypes.array,
  data: PropTypes.array,
  questionData: PropTypes.array,
  comment: PropTypes.array,
}

const skillsMap = {
  'Computer / Tech': <ComputerIcon />,
  'Practical / Hands On': <BoxIcon />,
  'Writing / Literacy': <PenIcon />,
  'Written / Literacy': <PenIcon />,
  'Maths / Number': <MathIcon />,
  'Maths / Numbers': <MathIcon />,
  Driving: <VehicleIcon />,
  'Leadership skills': <ManWithMoneyIcon />,
  'Ability and interest in working in a team': <MeetingIcon />,
  Communication: <CollaborationIcon />,
  'Problem solving': <ManagementIcon />,
  'Work ethic': <LecturerIcon />,
  'Flexibility and adaptability to situations': <StreetViewIcon />,
  Interaction: <RomanceIcon />,
  Analytical: <AnalyticalIcon />,
  Management: <Management2Icon />,
  Design: <DesignIcon />,
  Marketing: <MarketingIcon />,

  'Bulk / Heavy Haulage': <DrivingBulkIcon />,
  'Earth-moving / Tipper Truck': <DrivingTipperIcon />,
  'General Cartage': <DrivingCartageIcon />,
  'Courier - Residential / Rural': <DrivingCourierIcon />,
  'Rubbish / Recycling Truck': <DrivingRubbishIcon />,
  'Passenger Service': <DrivingPassengerIcon />,
  'Logging Trucks': <DrivingLoggingIcon />,
  'Refrigerated Trucks': <DrivingFridgeIcon />,
  'Tanker Trucks': <DrivingTankerIcon />,
  'Container Trucks': <DrivingContainerIcon />,
  'Over-Dimension': <DrivingOverIcon />,
  'Furniture Removal Transport': <DrivingFurnitureIcon />,
  'Line Haul / Freight Forwarding': <DrivingHaulIcon />,
  'Hiab / Crane Truck': <DrivingHiabIcon />,
  'Vehicle Recovery': <DrivingRecoveryIcon />,
  'Concrete Truck': <DrivingConcreteIcon />,
  'Taxi or Small Passenger Transport': <DrivingSmallIcon />,

  'Diggers / Excavators': <MachineDiggerIcon />,
  BullDozers: <MachineDozerIcon />,
  Graders: <MachineGraderIcon />,
  Rollers: <MachineRollerIcon />,
  'Skidder (Logging)': <MachineSkidderIcon />,
  Loader: <MachineLoaderIcon />,
  'Container Loading Equipment': <MachineContainerIcon />,
  Forklift: <MachineForkliftIcon />,
  'Combine Harvester': <MachineCombinerIcon />,
  'Forage Harvester': <MachineForagerIcon />,
  'Maize Chopper': <MachineChopperIcon />,
  'Balers / Hay Harvesting': <MachineBalerIcon />,
  'Agricultural Other': <MachineAgriculturalIcon />,
  'Roading Other': <MachineRoadingIcon />,
  'Mining Industry Special Vehicles': <MachineMiningIcon />,
}

function getSkillDescription(item, additionData) {
  const additionItem = additionData.find(
    (subitem) => subitem.value === item.value
  )
  if (!additionItem) return null
  return additionItem.additional
    ? additionItem.additional.find(
        (subitem) => subitem.value === +item.additionalValues[0]
      )?.description
    : additionItem.description
}

const points = [1, 2, 3, 4, 5]

export default function Skills({driver, title, comment, ...props}) {
  const data = driver ? props.types : props.data
  const additionData = driver ? props.typesData : props.questionData

  if (!data && !additionData) return null
  return (
    <Block className="Skills" title={title}>
      {data && (
        <ul>
          {data.map((item) => (
            <li key={item.value} class="Skills-Item">
              <div class="Skills-Block">
                <h3 class="Skills-Title">
                  <div class="Skills-Icon">{skillsMap[item.value]}</div>
                  {item.value}
                </h3>
                <p class="Skills-Text">
                  {getSkillDescription(item, additionData)}
                </p>
              </div>
              {item.additionalValues && (
                <ul class="Skills-Points">
                  {points.map((point) => (
                    <li
                      key={point}
                      class={cn('Skills-Point', {
                        'Skills-Point_active':
                          item.additionalValues &&
                          point === +item.additionalValues[0],
                      })}
                    />
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
      {comment && <Quote text={comment[0].value} />}
    </Block>
  )
}
