import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={18}
      viewBox="0 0 20 18"
      width={20}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 0a2 2 0 100 4 2 2 0 000-4zm7 0a2 2 0 100 4 2 2 0 000-4zm7 0a2 2 0 100 4 2 2 0 000-4zM2 5a2 2 0 00-2 2v6a2 2 0 002 2h3v3h2v-5H2V7h2v4h12V7h2v6h-5v5h2v-3h3a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2v2h-1V7a2 2 0 00-2-2H9a2 2 0 00-2 2v2H6V7a2 2 0 00-2-2zm7 2h2v2H9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
