import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.707 3L1.293 4.414 4 7.121v7.586H2a1 1 0 00-1 1v5a1 1 0 001 1h15.117a5.007 5.007 0 004.744-3.418l2.088-6.266a1 1 0 00-1.09-1.306l-3.859.55v-3.56a1 1 0 00-1-1H9a1.002 1.002 0 00-.707 1.707L10 10.121v4.586H6v-8A.997.997 0 005.707 6zm8.707 5.707H17v2.848l-1.14.162a.998.998 0 00-.567.283l-2.707 2.707H12v-5A.997.997 0 0011.707 9zm10.125 4.219l-1.576 4.73a2.992 2.992 0 01-2.846 2.051H3v-3h10c.265 0 .52-.105.707-.293l2.764-2.764z" />
    </svg>
  )
}

export default SvgComponent
