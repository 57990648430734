import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor">
        <path d="M17 15.5a3 3 0 100 6 3 3 0 000-6zm0 4.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25zM6 15.5a3 3 0 100 6 3 3 0 000-6zm0 4.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25zm7-6.25h8l-1-2h-7v2z" />
        <path d="M19 16.5h2v2h-2z" />
        <path d="M19.276 8.053a.998.998 0 00-.894-.553H13v11h2v-9h2.764L20 13.972V18.5h1a1 1 0 001-1v-4l-2.724-5.447z" />
        <path d="M4 2.5h7.02L15 6.493V16.5a2.003 2.003 0 01-1.851 1.995L13 18.5H8.037v-2H13V7.319L10.19 4.5H4v14a2.003 2.003 0 01-1.995-1.851L2 16.5v-12c0-1.052.818-1.918 1.851-1.995L4 2.5z" />
        <path d="M14.714 7.5H10V2.786z" />
      </g>
    </svg>
  )
}

export default SvgComponent
