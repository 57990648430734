import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M.552 6.396a4.082 4.082 0 014.275-2.842l4.038.378a6.024 6.024 0 015.102 3.944V6.464a1 1 0 01.883-.993l.116-.007h5.687a1 1 0 01.832.445l.063.108 2 4a1 1 0 01.098.331l.007.116v6a1 1 0 01-.883.993l-.117.007-1.035.001a3.5 3.5 0 01-6.93 0h-7.07a3.5 3.5 0 11-.302-2l6.65-.001.002-3.514a4.99 4.99 0 01-.32.678l-.154.251a4.99 4.99 0 01-6.903 1.462l-4.13-2.686a4.647 4.647 0 01-1.966-5.06zm3.601 9.068a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm14 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm3.499-4h-5.686v2.768a3.484 3.484 0 011.995-.763l.192-.005a3.5 3.5 0 013.163 2h.336v-4zM4.64 5.545a2.082 2.082 0 00-2.21 1.551 2.647 2.647 0 001.12 2.883l4.13 2.686a2.99 2.99 0 004.546-1.844 4.024 4.024 0 00-3.549-4.898zm15.393 1.918h-4.069v2h5.069l-1-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
