import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor">
        <path d="M11.791 17A5.497 5.497 0 016.5 21 5.5 5.5 0 014 10.601V4a1 1 0 01.883-.993L5 3h5a1 1 0 01.877.519l.051.11L12.677 8H15V5a2 2 0 011.85-1.995L17 3h2v2h-2v3h3a1 1 0 01.993.883L21 9l.001 5.337A3.5 3.5 0 1116.035 17zm7.709-1a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-13-4a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm2.823-7H6v5.023A5.5 5.5 0 0111.977 15H17l.048.003A3.49 3.49 0 0119 14.035V10h-7a1 1 0 01-.877-.519l-.051-.11L9.323 5z" />
        <path d="M6.5 14a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
      </g>
    </svg>
  )
}

export default SvgComponent
