import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={48} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 20.238a3 3 0 01-3-3 3 3 0 11-5.627-1.45V3.763h13.023l-.001 10.941A2.986 2.986 0 0116 14.238c.858 0 1.632.36 2.178.937V3.762h18.344l-.001 11.476H38v-9h5.382c.379 0 .725.214.894.553L47 12.238v5h-2a3 3 0 11-6 0h-2.564a3 3 0 01-5.75 1.2 3 3 0 01-5.75-1.2H19a3 3 0 11-6 0 3 3 0 01-3 3zm32-4.25a1.251 1.251 0 101.25 1.25c0-.689-.561-1.25-1.25-1.25zm-8.564 0a1.251 1.251 0 000 2.5 1.251 1.251 0 000-2.5zm-5.5 0a1.251 1.251 0 000 2.5 1.251 1.251 0 000-2.5zm-11.936 0a1.251 1.251 0 101.25 1.25c0-.689-.561-1.25-1.25-1.25zm-6 0a1.251 1.251 0 101.25 1.25c0-.689-.561-1.25-1.25-1.25zm-6 0a1.251 1.251 0 101.25 1.25c0-.689-.561-1.25-1.25-1.25zM34.522 5.762H20.177v9.476H25.7a3 3 0 014.987.8 2.999 2.999 0 013.835-1.597V5.76zm-22.126 0H3.374l-.001 8.542a2.992 2.992 0 012.863.934h1.528c.55-.614 1.347-1 2.236-1a2.99 2.99 0 012.236 1h.159V5.762zm32.368 6.476H40v2.764a2.989 2.989 0 012-.764 2.99 2.99 0 012.236 1H45V12.71l-.236-.472zm-2-4H40v2h3.763l-.999-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
