import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.052 2.94l2.768.438a1.52 1.52 0 012.173.162l3.656 3.646c.67.67.741 1.722.19 2.472l-.104.129-1.555 1.77.06.003c.93.076 1.671.821 1.74 1.753l.005.142v2.851h3.625V7.752a.95.95 0 01.84-.945l.11-.006h5.407c.32 0 .615.16.79.423l.06.102 1.901 3.803a.95.95 0 01.094.315l.006.11v5.703a.95.95 0 01-.84.945l-.11.006h-.984a3.328 3.328 0 01-6.587 0h-4.345a3.328 3.328 0 01-6.587-.952l-.034.001v-3.802c0-1.002.776-1.823 1.76-1.896l.141-.005h.422l2.652-3.022-2.323-2.318.312 1.97L3.828 9.95a.95.95 0 01-.96-1.638l.097-.056L5.2 7.116l-.21-1.321a1.53 1.53 0 01-.501-.544l-1.37-.217-1.14 2.237a.95.95 0 01-1.177.46L.7 7.687A.95.95 0 01.24 6.51l.044-.102L2.052 2.94zM7.66 16.307a1.426 1.426 0 100 2.851 1.426 1.426 0 000-2.851zm10.931 0a1.426 1.426 0 100 2.851 1.426 1.426 0 000-2.851zm3.326-3.154H16.51l-.001 1.983a3.311 3.311 0 011.891-.725l.19-.005c1.326 0 2.472.776 3.006 1.9h.32l-.001-3.153zm-12.832.302H6.232v1.271a3.32 3.32 0 011.427-.32 3.3 3.3 0 011.425.32v-1.271zm11.294-4.753H16.51v2.55h5.144l-1.276-2.55z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
