import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 5 24"
      width={5}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="currentColor"
          strokeWidth={2.667}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.518 2.533v18.134"
        />
        <path
          d="M2.518 0a2.133 2.133 0 100 4.267 2.133 2.133 0 000-4.267zm0 12.533a2.133 2.133 0 100 4.267 2.133 2.133 0 000-4.267zm0-6.387a2.133 2.133 0 100 4.266 2.133 2.133 0 000-4.266zm0 13.159a2.133 2.133 0 100 4.266 2.133 2.133 0 000-4.266z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
