import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M12 2C6.489 2 2 6.489 2 12s4.489 10 10 10 10-4.489 10-10S17.511 2 12 2zm0 2c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8zm1.033 4c-2.162 0-3.599 1.512-4.398 2.842a9.154 9.154 0 00-.979 2.22C6.696 13.88 6 14.835 6 16h2c0-.133.211-.559.602-1.025.715.437 1.9 1.025 3.298 1.025 1.652 0 2.957-.64 3.881-1.53.925-.89 1.522-1.978 2.088-2.978l.346-.607-.453-.533S15.888 8 13.033 8zm0 2c1.192 0 2.094.688 2.64 1.17-.411.692-.811 1.41-1.278 1.86-.634.61-1.313.97-2.495.97-.5 0-1.01-.171-1.466-.377.781-.37 1.67-.623 2.566-.623v-2c-.803 0-1.573.136-2.295.36.575-.776 1.31-1.36 2.328-1.36z" />
    </svg>
  )
}

export default SvgComponent
