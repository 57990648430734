import {h} from 'preact'
import PropTypes from 'prop-types'
import cn from 'classnames'

import './Block.scss'
import Animate, {EFFECT} from './Animate'

Block.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  zoomEffect: PropTypes.bool,
}

export default function Block({children, className, title, zoomEffect}) {
  return (
    <section class="Block">
      <div class={cn(className)}>
        {title && (
          <h2 class="Block-Title">
            <Animate effect={EFFECT.right}>
              <span>{title}</span>
            </Animate>
          </h2>
        )}
        <Animate effect={zoomEffect ? EFFECT.zoomIn : EFFECT.bottom}>
          {' '}
          {children}
        </Animate>
      </div>
    </section>
  )
}
