import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M12 2c-2.117 0-4.092.578-5.576 1.77C4.94 4.96 4 6.797 4 9c0 1.778.881 3.493 1.95 5.021A20.24 20.24 0 009 17.443V20c0 1.093.907 2 2 2h2c1.093 0 2-.907 2-2v-2.557a20.202 20.202 0 003.05-3.422C19.12 12.493 20 10.778 20 9c0-2.203-.94-4.04-2.424-5.23C16.092 2.578 14.117 2 12 2zm0 2c1.749 0 3.275.488 4.324 1.33C17.374 6.172 18 7.337 18 9c0 1.062-.644 2.524-1.588 3.875-.878 1.256-1.964 2.355-2.842 3.125h-3.14c-.878-.77-1.964-1.87-2.842-3.125C6.644 11.525 6 10.062 6 9c0-1.663.626-2.828 1.676-3.67C8.726 4.488 10.25 4 12 4zm-1 14h2v2h-2v-2z" />
    </svg>
  )
}

export default SvgComponent
