import {h} from 'preact'
import PropTypes from 'prop-types'

import './Video.scss'
import VideoSvg from './svg/video'
import PlaySvg from './svg/play'
import Animate, {EFFECT} from './common/Animate'
import {ORIGIN, UUID, IS_PDF} from '../utils/constants'

Video.propTypes = {
  videoExists: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string,
}

export default function Video({videoExists, url}) {
  if (!videoExists && !IS_PDF) return null

  const profileLink = IS_PDF ? `${ORIGIN}/${UUID}` : undefined

  return (
    <section className="Video">
      {IS_PDF ? (
        <a className="Video-Block" href={profileLink}>
          <PlaySvg class="Video-Play" />
          <p class="Video-Label">{profileLink}</p>
        </a>
      ) : (
        <Animate
          controls
          el="video"
          className="Video-Content"
          preload="metadata"
          src={`${url}#t=1`}
          effect={EFFECT.bottom}
        />
      )}
      <Animate className="Video-IconWrapper" effect={EFFECT.zoomOut}>
        <VideoSvg class="Video-Icon" />
      </Animate>
    </section>
  )
}
