import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor">
        <path d="M17.5 12a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm0 2a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-12-2a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm0 2a1.5 1.5 0 110 3 1.5 1.5 0 010-3z" />
        <path d="M15 14v2H8v-2zM3 14v2H1v-2z" />
        <path d="M14 15h-2V6a1 1 0 01.883-.993L13 5h4.963a3 3 0 012.447 1.264l.107.162 2.038 3.308c.255.414.404.882.438 1.365l.007.207V15a1 1 0 01-.883.993L22 16h-3v-2h2v-2.694c0-.139-.029-.276-.084-.402l-.064-.122-2.037-3.307a1 1 0 00-.723-.467L17.963 7H14v8z" />
      </g>
    </svg>
  )
}

export default SvgComponent
