import {h} from 'preact'
import PropTypes from 'prop-types'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import './Loader.scss'

Loader.propTypes = {
  open: PropTypes.bool.isRequired,
}

export default function Loader({open}) {
  return (
    <Backdrop unmountOnExit className="Loader" open={open}>
      <CircularProgress />
    </Backdrop>
  )
}
