import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.333 18.669c-.009-5.995 3.84-9.346 8.67-9.336l.555.001H16c2.608 0 3.92 1.086 5.563 3.64l.155.243c.962 1.496 1.446 1.978 2.253 2.131 4.313.817 6.696 2.666 6.696 5.985V24a1.33 1.33 0 01-1.332 1.333l-2.23.001a4.002 4.002 0 01-7.544 0H12.44a4.002 4.002 0 01-7.544 0H2.658A1.326 1.326 0 011.333 24zm22 3.998a1.333 1.333 0 100 2.666 1.333 1.333 0 000-2.666zm-14.666 0a1.333 1.333 0 100 2.666 1.333 1.333 0 000-2.666zM28 21.333c0-1.193-.625-2.05-2.06-2.666L4 18.666v4h.894a4.002 4.002 0 017.545 0h7.122a4.002 4.002 0 017.545 0h.893zm-11.822-9.33L16 12h-1.205l-1.432.001-.03 3.998 7.078.001a16.052 16.052 0 01-.771-1.088l-.165-.254-.155-.24C18.12 12.551 17.455 12 16 12zM9.997 12c-2.654-.006-4.797 1.337-5.626 3.999h6.295l-.031-3.998h-.378z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgComponent
