import {h, Fragment} from 'preact'
import {useState} from 'preact/hooks'
import PropTypes from 'prop-types'
import axios from 'axios'

import './User.scss'
import MailIcon from './icons/mail'
import HousesIcon from './icons/houses'
import PhoneIcon from './icons/phone'
import Loader from './common/Loader'
import Block from './common/Block'

User.propTypes = {
  account: PropTypes.object,
  profile: PropTypes.object.isRequired,
  photo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  setProfile: PropTypes.func.isRequired,
  onSnackbarOpen: PropTypes.func,
}

export default function User({
  account,
  profile,
  photo,
  name,
  email,
  phone,
  city,
  setProfile,
  onSnackbarOpen,
}) {
  const [loading, setLoading] = useState(false)

  const items = [
    {id: 1, Icon: MailIcon, text: email},
    {id: 2, Icon: PhoneIcon, text: phone},
    {id: 3, Icon: HousesIcon, text: city},
  ]

  const handlePhotoReplace = async (e) => {
    setLoading(true)
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    try {
      const {
        data: {files},
      } = await axios.post('/file/upload', formData)
      const {data} = await axios.post(
        '/recipient/current',
        {
          photo: files[0],
        },
        {headers: {'VPM-Recipient': account.code}}
      )
      setProfile({...profile, photo: data.data.photo})
      setLoading(false)
    } catch ({response}) {
      onSnackbarOpen(response)
      setLoading(false)
    }
  }

  const handlePageLoaded = (readyState) => {
    if (readyState === 'complete') {
      window.pageLoaded = true
    }
  }

  const handleImageLoad = () => {
    handlePageLoaded(document.readyState)
    document.addEventListener('readystatechange', (e) =>
      handlePageLoaded(e.target.readyState)
    )
  }

  return (
    <>
      <Loader open={loading} />
      <Block zoomEffect className="User">
        <div class="User-Wrapper">
          <img
            class="User-Image"
            width={160}
            height={160}
            src={photo}
            alt={name}
            onLoad={handleImageLoad}
            onError={handleImageLoad}
          />
          {account && (
            <label class="User-Button" role="button">
              <input
                accept="image/*"
                className="User-File"
                type="file"
                onChange={handlePhotoReplace}
                disabled={loading}
              />
              Replace photo
            </label>
          )}
        </div>
        <h1 class="User-Title">{name}</h1>
        <ul>
          {items.map(({Icon, ...item}) => (
            <li key={item.id} class="User-Item">
              <span class="User-Icon">
                <Icon />
              </span>
              {item.text}
            </li>
          ))}
        </ul>
      </Block>
    </>
  )
}
