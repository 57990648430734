import {h, Fragment} from 'preact'
import PropTypes from 'prop-types'

import './Footer.scss'
import TipIcon from './icons/tip'
import Animate, {EFFECT} from './common/Animate'
import {UUID, IS_PDF} from '../utils/constants'

Footer.propTypes = {
  text: PropTypes.array,
}

const printProfilePage = () => {
  window.print()
}

export default function Footer({text}) {
  return (
    <section className="Footer" center="xs">
      {text && (
        <Fragment>
          <h2 class="Footer-Title">favourite quote</h2>
          <Animate className="Footer-Text" effect={EFFECT.bottom}>
            <q>{text[0].value}</q>
          </Animate>
          <TipIcon class="Footer-Icon" />
        </Fragment>
      )}
      {!IS_PDF && (
        <>
          <div className="Footer-Button-Wrapper">
            <a
              class="Footer-Button Button Button_color_black"
              href={`${process.env.REACT_APP_PROFILE_URL}/${UUID}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download pdf file
            </a>
            <button
              class="Footer-Button Button Button_color_black"
              onClick={printProfilePage}
            >
              Print profile
            </button>
          </div>
        </>
      )}
    </section>
  )
}
