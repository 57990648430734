import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
        <filter id="prefix__b">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        filter="url(#prefix__a)"
        transform="translate(-170 -603)"
        fill="none"
        fillRule="evenodd"
      >
        <g filter="url(#prefix__b)" transform="translate(170 603)">
          <path
            fill="#000"
            fillRule="nonzero"
            d="M6 3c-1.103 0-2 .897-2 2v10c0-1.474.81-2.75 2-3.443V9h14.002L20 19h-4a3.96 3.96 0 01-.557 2H20c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2H6zm0 2h14.002v2H6V5zm10 7a2 2 0 00-2 2c0 .256.051.5.139.725l-1.84 2.3A2.14 2.14 0 0012 17c-.175 0-.349.023-.518.068L9.93 15.516a2 2 0 10-3.791.211l-2.92 3.648 1.562 1.25 2.92-3.65A2 2 0 008 17c.175 0 .349-.023.518-.068l1.552 1.552a2 2 0 103.791-.211l1.844-2.302c.097.014.194.029.295.029a2 2 0 100-4z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
