import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2H0v2h24v-2h-4c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2h16l.002 10H4zm9.5 1.586L12.086 9l-2.172 2.172-1.586-1.586L6.914 11l3 3 3.586-3.586 2.086 2.086L17 11.086 14.914 9z" />
    </svg>
  )
}

export default SvgComponent
