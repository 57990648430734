import {h} from 'preact'
import {useState} from 'preact/hooks'
import PropTypes from 'prop-types'
import axios from 'axios'
import TextField from '@material-ui/core/TextField'

import './LoginForm.scss'
import CloseIcon from './icons/close'

LoginForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  setAccount: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  onSnackbarOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

const fieldProps = {
  required: true,
  fullWidth: true,
  variant: 'filled',
  margin: 'dense',
  InputProps: {
    disableUnderline: true,
  },
  InputLabelProps: {required: false},
}

const initialValues = {email: '', password: ''}

export default function LoginForm({
  loading,
  setAccount,
  setLoading,
  onSnackbarOpen,
  onClose,
}) {
  const [values, changeValues] = useState(initialValues)

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    axios
      .post('/recipient/auth', values)
      .then(({data}) => {
        setAccount(data)
        setLoading(false)
      })
      .catch(({response}) => {
        onSnackbarOpen(response)
        setLoading(false)
      })
  }

  const handleChange = ({target: {name, value}}) =>
    changeValues({
      ...values,
      [name]: value,
    })

  return (
    <form class="LoginForm" autoComplete="off" onSubmit={handleSubmit}>
      <button
        type="button"
        class="LoginForm-Close"
        disabled={loading}
        onClick={onClose}
      >
        <CloseIcon />
      </button>
      <h2 class="LoginForm-Title">Log in</h2>
      <div class="LoginForm-Fields">
        <TextField
          {...fieldProps}
          name="email"
          label="Email"
          type="email"
          disabled={loading}
          onChange={handleChange}
        />
        <TextField
          {...fieldProps}
          name="password"
          label="Password"
          type="password"
          disabled={loading}
          onChange={handleChange}
        />
      </div>
      <button
        type="submit"
        class="Button Button_color_black"
        disabled={loading}
      >
        Next
      </button>
    </form>
  )
}
