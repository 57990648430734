import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 3L4.187 6.473A2.008 2.008 0 003 8.299v12.795h4.832a.99.99 0 00.326 0h7.674a.99.99 0 00.326 0H21V8.299c0-.787-.469-1.506-1.188-1.826l-.001-.002zm0 2.188l7 3.11v10.796h-2v-9H7v9H5V8.299zm-3 6.906h6v7h-2v-1h-2v1H9z" />
    </svg>
  )
}

export default SvgComponent
