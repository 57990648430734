import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={18}
      viewBox="0 0 18 18"
      width={18}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 0a1 1 0 00-1 1v7l2-2h5a1 1 0 001 1h8l2 2V2a1 1 0 00-1-1h-6a1 1 0 00-1-1zm1 2h7v2H2zm9 1h5v2h-5zM4 9a2 2 0 100 4 2 2 0 000-4zm10 0a2 2 0 100 4 2 2 0 000-4zM4 15c-1.335 0-4 .67-4 2v1h8v-1c0-1.33-2.665-2-4-2zm10 0c-1.335 0-4 .67-4 2v1h8v-1c0-1.33-2.665-2-4-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
