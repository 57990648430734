import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
        <filter id="prefix__b">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        filter="url(#prefix__a)"
        transform="translate(-170 -759)"
        fill="none"
        fillRule="evenodd"
      >
        <g filter="url(#prefix__b)" transform="translate(170 759)">
          <path
            fill="#000"
            fillRule="nonzero"
            d="M8 2L2 8l5 5-4 4v4h4l4-4 5 5 6-6-5-5 4.707-4.707a.999.999 0 000-1.414l-2.586-2.586a.996.996 0 00-1.414 0L13 7 8 2zm10.414 2.414l1.172 1.172-1.293 1.293-1.172-1.172 1.293-1.293zM8 4.828l3.586 3.586-3.172 3.172L4.828 8 8 4.828zm7.707 2.293l1.172 1.172L6.172 19H5v-1.172L15.707 7.121zm-.121 5.293L19.172 16 16 19.172l-3.586-3.586 3.172-3.172z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
