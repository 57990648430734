import {h, Fragment} from 'preact'
import {useState} from 'preact/hooks'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'

import './Login.scss'
import AccountIcon from './icons/account'
import Loader from './common/Loader'
import LoginForm from './LoginForm'

Login.propTypes = {
  account: PropTypes.object,
  setAccount: PropTypes.func.isRequired,
  onSnackbarOpen: PropTypes.func.isRequired,
}

export default function Login({account, setAccount, onSnackbarOpen}) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  return (
    <>
      <Loader open={loading} />
      <div class="Login">
        {account ? (
          <div class="Login-Account">
            <p class="Login-Name">
              <AccountIcon class="Login-Icon" />
              {account.data.name}
            </p>
            <span class="Login-Separator" />
            <a
              class="Login-Link"
              href={`https://course.present.me/user/${account.code}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              VPM Course
            </a>
          </div>
        ) : (
          <button class="Login-Button" onClick={handleOpen}>
            <AccountIcon class="Login-Icon" />
            Login
          </button>
        )}
        <Dialog
          disablePortal
          scroll="body"
          open={!account && open}
          onClose={handleClose}
        >
          <LoginForm
            loading={loading}
            setAccount={setAccount}
            setLoading={setLoading}
            onSnackbarOpen={onSnackbarOpen}
            onClose={handleClose}
          />
        </Dialog>
      </div>
    </>
  )
}
