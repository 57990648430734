import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M8 2c-.75 0-1.38.372-1.855.822-.476.45-.845 1.002-1.153 1.594C4.376 5.599 4 6.901 4 8c0 1.85 1.284 3.406 3 3.855V22h2V11.855c1.716-.45 3-2.005 3-3.855 0-1.099-.376-2.4-.992-3.584-.308-.592-.677-1.144-1.153-1.594C9.38 2.372 8.751 2 8 2zm7 0v20h2v-5.145c1.716-.45 3-2.005 3-3.855 0-1.03-.256-3.461-.773-5.834-.26-1.186-.577-2.33-1.008-3.258-.216-.464-.454-.88-.79-1.234C17.096 2.32 16.599 2 16 2h-1zM8 4c.078 0 .239.047.48.275.242.23.52.614.754 1.065.47.902.766 2.102.766 2.66 0 1.117-.883 2-2 2s-2-.883-2-2c0-.558.296-1.758.766-2.66.234-.451.512-.836.754-1.065C7.76 4.047 7.922 4 8 4zm9 2.695c.088.328.197.548.273.897.483 2.213.727 4.78.727 5.408 0 .723-.436 1.239-1 1.59V6.695z" />
    </svg>
  )
}

export default SvgComponent
