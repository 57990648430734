import {h, Fragment} from 'preact'
import PropTypes from 'prop-types'

import './VehicleTypes.scss'
import FlagIcon from './icons/flag'
import VehicleTruckIcon from './icons/vehicle-truck'
import VehicleSemiIcon from './icons/vehicle-semi'
import VehicleATrainIcon from './icons/vehicle-a-train'
import VehicleBTrainIcon from './icons/vehicle-b-train'
import Block from './common/Block'
import Donut from './common/Donut'

VehicleTypes.propTypes = {
  goals: PropTypes.array,
  types: PropTypes.array,
  typesData: PropTypes.array,
}

const typesMap = {
  'Truck and Trailer': <VehicleTruckIcon />,
  Semi: <VehicleSemiIcon />,
  '"A"Train': <VehicleATrainIcon />,
  '"B"Train': <VehicleBTrainIcon />,
}

const Vehicle = ({value, type, typesData}) => (
  <li class="VehicleTypes-Item">
    <Donut value={value} />
    <div class="VehicleTypes-Block">
      <h3 class="VehicleTypes-Title">
        <div class="VehicleTypes-Icon">{typesMap[type]}</div>
        {type}
      </h3>
      <p class="VehicleTypes-Label">
        {
          typesData
            .find((item) => item.value === type)
            .additional.find((item) => item.value === value).description
        }
      </p>
    </div>
  </li>
)

export default function VehicleTypes({goals, types, typesData}) {
  if (!goals && !types) return null

  return (
    <Block class="VehicleTypes" title="Vehicle Type Experience">
      {types && (
        <ul class="VehicleTypes-List">
          {types.map((type) => (
            <Vehicle
              key={type.value}
              value={+type.additionalValues[0]}
              type={type.value}
              typesData={typesData}
            />
          ))}
        </ul>
      )}
      {goals && (
        <div class="VehicleTypes-SubBlock">
          <FlagIcon class="VehicleTypes-SubIcon" />
          <h4 class="VehicleTypes-Subtitle">Commercial Driving Goals</h4>
          <p class="VehicleTypes-Subtext">
            <q>{goals[0].value}</q>
          </p>
        </div>
      )}
    </Block>
  )
}
