import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={23}
      viewBox="0 0 24 23"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.697.209L9.77 2.506l1.533 1.285 1.927-2.297zM2.8 1.385L.2 2.885l1 1.73 2.598-1.5zm18.402 0l-1 1.73 2.598 1.5 1-1.73zM12 5a2 2 0 100 4 2 2 0 000-4zm-6.293.293L4.293 6.707l4.783 4.783A1.942 1.942 0 009 12v11h2v-5h2v5h2V12c0-.177-.032-.346-.076-.51l4.783-4.783-1.414-1.414-4.783 4.783A1.942 1.942 0 0013 10h-2c-.177 0-.346.032-.51.076zM11 12h2v4h-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
