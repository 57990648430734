import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="currentColor" strokeWidth={2} fill="none" fillRule="evenodd">
        <path
          d="M15.43 15.5a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0zm-12 0a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0zm5-.5h7m-12 0h-2v-3"
          strokeLinejoin="round"
        />
        <path
          d="M13.43 15V6h4.963a2 2 0 011.703.951l2.037 3.306c.194.316.297.679.297 1.049V15h-3"
          strokeLinejoin="round"
        />
        <path d="M13.726 9.834h8.051" />
      </g>
    </svg>
  )
}

export default SvgComponent
