import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.175 19a3.5 3.5 0 01-6.929 0H2.211a1 1 0 01-.993-.883L1.21 18v-3.974L.79 5.047a1 1 0 01.628-.976l.11-.036 7.422-2a1 1 0 011.254.851L10.21 3v3.5a2.5 2.5 0 01-4.995.165L5.211 6.5h2a.5.5 0 00.992.09l.008-.09V4.305L2.824 5.756 3.071 11h8.14V7a1 1 0 01.883-.993L12.211 6h6a1 1 0 01.608.206l.099.087 4 4a1 1 0 01.284.576l.009.131v7a1 1 0 01-.884.994l-.116.006h-1.036a3.5 3.5 0 01-6.929 0zm7.536-2a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-11 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm11.085-9H13.21v5h-10v4h.338a3.5 3.5 0 016.326 0h4.674a3.5 3.5 0 016.326 0h.336v-5.586L17.796 8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
