import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.188 2l-1.854 1.854-4.152 4.152L5.357 9.83l1.414 1.414 1.118-1.117 1.914 1.914L2 19.844l1.414 1.414 7.803-7.803 2.086 2.086-1.157 1.156 1.415 1.414 1.863-1.865 4.152-4.152 2.014-2.012-1.416-1.414-1.305 1.305-5.412-5.414 1.145-1.145zM12.04 5.975l5.414 5.412-2.738 2.738-5.414-5.412zm.838 13.576v2h9v-2z" />
    </svg>
  )
}

export default SvgComponent
