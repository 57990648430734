import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M16.488 5.25a1 1 0 01.73.317l.179.23 1.99 2.709 2.915.001a1 1 0 01.993.883l.007.117v5.743a1 1 0 01-.884.993l-.116.007h-1.777v-2h.777v-3.744l-2.42.001a1 1 0 01-.73-.317l-.077-.09-2.092-2.85h-1.681v8h-2v-9a1 1 0 01.883-.993l.117-.007h3.186zM11.542 5.25v10h-2v-8H.7v-2z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <path
          d="M15.27 15.25a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0zm-11.07 0a2.5 2.5 0 105 0 2.5 2.5 0 00-5 0zm5.74-.5h5.33"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinejoin="round"
        />
        <path
          d="M.7 11.75a3.5 3.5 0 010 7v-2a1.5 1.5 0 000-3z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
