import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={12}
      viewBox="0 0 12 12"
      width={12}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.2.449L0 3.619V10.2c0 .656.544 1.2 1.2 1.2h9.6c.656 0 1.2-.544 1.2-1.2V3.619L7.821.464 5.91 1.74zm3.579 1.487l3.021 2.28V10.2H8.4V9h1.2V7.8H8.4V6.6h1.2V5.4H8.4V3.619L6.925 2.505zM4.2 1.95l3 2.265V10.2h-6V4.216zM2.4 5.4v1.2h1.2V5.4zm2.4 0v1.2H6V5.4zM2.4 7.8V9h1.2V7.8zm2.4 0V9H6V7.8z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
