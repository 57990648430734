import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={18}
      viewBox="0 0 18 18"
      width={18}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 0v18h2v-7h7.383l1 2H18V2h-6.383l-1-2zm2 2h7.383l1 2H16v7h-4.383l-1-2H2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
