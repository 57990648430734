import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor">
        <path d="M18.5 14a3 3 0 100 6 3 3 0 000-6zm0 4.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25zM6.5 14a3 3 0 100 6 3 3 0 000-6zm0 4.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25zm8-6.25h8l-1-2h-7v2z" />
        <path d="M20.5 15h2v2h-2z" />
        <path d="M20.776 6.553A.998.998 0 0019.882 6H14.5v11h2V8h2.764l2.236 4.472V17h2v-5l-2.724-5.447zM8.5 8h-7v2h7V8z" />
        <path d="M.5 4v2h14v9h-6v2h8V4z" />
        <path d="M3.5 14l2-2h-4v5h3v-2h-1z" />
      </g>
    </svg>
  )
}

export default SvgComponent
