import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={14}
      viewBox="0 0 9 14"
      width={9}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.4 11.7V1.5A1.5 1.5 0 006.9 0H1.5A1.5 1.5 0 000 1.5v10.2a1.5 1.5 0 001.5 1.5h5.4a1.5 1.5 0 001.5-1.5zm-1.2.3h-6V1.2h.975l.434.868a.6.6 0 00.537.332h2.083a.6.6 0 00.536-.332L6.2 1.2h1z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
