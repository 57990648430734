import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={20}
      viewBox="0 0 19 20"
      width={19}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 0a2 2 0 100 4 2 2 0 000-4zm12 0a2 2 0 100 4 2 2 0 000-4zM2 6a2 2 0 00-2 2v12h2v-6h2v6h2v-9.828l3 3 2.783-2.682L11 16h1v4h2v-4h2v4h2v-4h1l-1.287-8.307A2.001 2.001 0 0015.734 6h-1.578c-.575 0-1.095.25-1.463.646L9 10.172l-3.578-3.58-.002.002A1.992 1.992 0 004 6zm0 2h2v4H2zm12.156 0h1.578l.932 6h-3.361z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
