import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={42} height={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path
          d="M37 10a3 3 0 100 6 3 3 0 000-6zm0 4.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25zM25 10a3 3 0 100 6 3 3 0 000-6zM5.299 10a3 3 0 100 6 3 3 0 000-6zM25 14.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25zm-19.701 0a1.251 1.251 0 010-2.5c.689 0 1.25.561 1.25 1.25s-.561 1.25-1.25 1.25zM33 8h8l-1-2h-7v2z"
          fillRule="nonzero"
        />
        <path fillRule="nonzero" d="M39 11h2v2h-2z" />
        <path
          d="M39.276 2.553A.998.998 0 0038.382 2H33v11h2V4h2.764L40 8.472V13h2V8l-2.724-5.447z"
          fillRule="nonzero"
        />
        <path fillRule="nonzero" d="M.154 0v2H33v9h-6v2h8V0z" />
        <path d="M7.154 4h-7v2h7zM7.283 11h16v2h-16z" />
        <path fillRule="nonzero" d="M2.154 10l2-2h-4v5h3v-2h-1z" />
      </g>
    </svg>
  )
}

export default SvgComponent
