import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor">
        <path d="M20 3a1 1 0 01.993.883L21 4v10a1 1 0 01-.445.832l-.108.062-1.447.724V18a1 1 0 01-.883.993L18 19H6a1 1 0 01-1-1v-2.382l-1.447-.724a1 1 0 01-.545-.77L3 14V4a1 1 0 01.883-.993L4 3h16zm-3 14v-3a1 1 0 00-.883-.993L16 13H8a1 1 0 00-1 1v3h3v-2h4v2h3zm2-12H5v8.382l.058.03A3.004 3.004 0 017 11.17V9a2 2 0 011.85-1.995L9 7h6a2 2 0 011.995 1.85L17 9l.001 2.171a3.004 3.004 0 011.941 2.24l.058-.03V5zm-4 4H9v2h6V9z" />
        <path d="M6 21h2l1-3H5v2a1 1 0 001 1zm12 0h-2l-1-3h4v2a1 1 0 01-1 1z" />
      </g>
    </svg>
  )
}

export default SvgComponent
