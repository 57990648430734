import {h, Fragment} from 'preact'
import PropTypes from 'prop-types'

import './Language.scss'
import FlagIcon from './icons/flag'
import Block from './common/Block'
import Donut from './common/Donut'

Language.propTypes = {
  speak: PropTypes.array,
  learnNew: PropTypes.array,
  learnNewWhy: PropTypes.array,
}

const labels = [
  'A beginner',
  'Elementary level',
  'Intermediate level',
  'Advanced language',
  'Native language',
]

export default function Language({speak, learnNew, learnNewWhy}) {
  if (!speak && !learnNew && !learnNewWhy) return null

  return (
    <Block className="Language" title="Language">
      {speak && (
        <ul class="Language-List">
          {speak.map(({value, level}) => (
            <li key={value} class="Language-Item">
              <Donut value={level} />
              <h3 class="Language-Title">{value}</h3>
              <p class="Language-Text">{labels[level - 1]}</p>
            </li>
          ))}
        </ul>
      )}
      {learnNew && (
        <Fragment>
          <FlagIcon class="Language-Icon" />
          <h4 class="Language-Subtitle">want to learn</h4>
          <p class="Language-Title">{learnNew[0].value}</p>
          {learnNewWhy && (
            <p class="Language-Subtext">
              <q>{learnNewWhy[0].value}</q>
            </p>
          )}
        </Fragment>
      )}
    </Block>
  )
}
