import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={20}
      viewBox="0 0 18 20"
      width={18}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 0a2 2 0 100 4 2 2 0 000-4zM7 5a2 2 0 00-2 2v5h1v4h2v-6H7V7h4v3h-1v6h2v-4h1V7a2 2 0 00-2-2zm-3 8.521c-2.183.5-4 1.413-4 2.979C0 19.263 5.657 20 9 20s9-.737 9-3.5c0-1.566-1.817-2.48-4-2.979v2.069c1.128.312 1.795.677 1.975.91-.367.476-2.729 1.5-6.975 1.5s-6.608-1.024-6.975-1.5c.18-.233.847-.598 1.975-.91z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
