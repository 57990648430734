import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor">
        <path d="M15 11a1 1 0 100 2 1 1 0 000-2zm-6 0a1 1 0 100 2 1 1 0 000-2z" />
        <path d="M11.998 2.688l-.001 1.623h1.937c.65 0 1.255.316 1.658.895l.097.15 2.491 5.072 3.713.742a2 2 0 011.6 1.804l.007.158v4.18a1 1 0 01-.445.831l-.108.063-1.67.836a3.501 3.501 0 01-6.44.27H8.163a3.5 3.5 0 01-6.387-.136 2 2 0 01-1.27-1.715l-.006-.15v-6a1 1 0 01.058-.335l.044-.105L3.35 5.286a1.997 1.997 0 011.555-.968l.161-.006H7V2.687h4.998zM5 16.312a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm13 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-.602-4H2.5v3.05a3.49 3.49 0 012.308-1.045L5 14.312a3.5 3.5 0 013.465 3h6.07a3.5 3.5 0 016.808-.54l.157-.079v-3.562l-4.102-.82zm-12.328-6l-1.966 4H8.5v-4h2v4h5.395l-1.961-4H5.07z" />
      </g>
    </svg>
  )
}

export default SvgComponent
