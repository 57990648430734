import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M12 2C6.24 2 1.618 6.867 2.024 12.713 2.392 18.002 7.005 22 12.306 22H13a2 2 0 002-2v-3a2 2 0 012-2h3a2 2 0 002-2v-.693c0-5.301-3.997-9.915-9.285-10.282A10.17 10.17 0 0012 2zm0 2c.19 0 .38.007.574.02 4.164.289 7.425 3.929 7.425 8.287V13h-3c-2.206 0-4 1.794-4 4v3h-.693c-4.358 0-7.999-3.263-8.287-7.426-.156-2.25.6-4.39 2.129-6.03A7.93 7.93 0 0111.999 4zm.5 1a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-4 1a1.5 1.5 0 10-.001 2.999A1.5 1.5 0 008.5 6zm8 1a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-10 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm3.5 4a2 2 0 10-.001 3.999A2 2 0 0010 14z" />
    </svg>
  )
}

export default SvgComponent
