import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.622 3L6.208 4.414l4.629 4.629-8 8a2.892 2.892 0 000 4.07l.293.293h.414l-.024-.01c.008.004.017.004.024.008v.002h.004c1.093.6 2.438.628 3.36-.293l8-8 3.671 3.672.707-.707 3.531-3.531-3.695-3.733 1.285-1.287-3.984-3.984-1.266 1.264L13.368 3zm2 2h2.91l7.465 7.54-1.418 1.417zm2.629 5.457l1.242 1.242-8 8c-.352.352-.89.352-1.242 0s-.352-.89 0-1.242z" />
    </svg>
  )
}

export default SvgComponent
