import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M5 3a1 1 0 00-1 1v6.61c-1.777.914-3 2.764-3 4.89C1 18.526 3.474 21 6.5 21c2.505 0 4.628-1.699 5.285-4h4.266c-.024.165-.051.329-.051.5 0 1.921 1.579 3.5 3.5 3.5s3.5-1.579 3.5-3.5c0-1.383-.825-2.578-2-3.145V9a1 1 0 00-1-1h-3V5h2V3h-2c-1.093 0-2 .907-2 2v3h-2.322l-1.75-4.371A1 1 0 0010 3H5zm1 2h3.322l1.75 4.371A1 1 0 0012 10h7v4.05c-.745.11-1.42.447-1.938.952L17 15h-5.025c-.255-2.793-2.618-5-5.475-5-.169 0-.335.01-.5.025V5zm.5 7c1.945 0 3.5 1.555 3.5 3.5S8.445 19 6.5 19 3 17.445 3 15.5 4.555 12 6.5 12zm0 2a1.5 1.5 0 10.001 3.001A1.5 1.5 0 006.5 14zm13 2c.84 0 1.5.66 1.5 1.5s-.66 1.5-1.5 1.5-1.5-.66-1.5-1.5.66-1.5 1.5-1.5z" />
    </svg>
  )
}

export default SvgComponent
