import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={20}
      viewBox="0 0 20 20"
      width={20}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.293.006a1.99 1.99 0 00-1.414.58l-3.52 3.52a1.315 1.315 0 01-1.88 0L3.064 5.52a3.345 3.345 0 004.71 0L11.292 2l.01.012 1.258 1.185L1.654 14.104l-.879 5.12 1.395-.238 3.726-.64L19.121 5.12a3.015 3.015 0 000-4.242C18.54.297 17.77.006 17 .006s-1.54.29-2.121.873l-.902.902-1.27-1.195c-.386-.386-.9-.58-1.414-.58zM17 1.994c.254 0 .508.1.707.299a.985.985 0 010 1.414l-.816.816-1.457-1.37.859-.86A.997.997 0 0117 1.994zm-2.982 2.574l1.457 1.371-8.057 8.057-1.414-1.414zM4.59 13.996l1.414 1.414-1.072 1.072-1.707.293.293-1.707z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
