import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 3c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h14l4 4-.012-18.002a2 2 0 00-2-1.998zm0 2h15.99l.006 13.17L18.828 17H4zm11.408 1.014v1.025h-2.41v1.313h4.715c0 2.53-2.09 4.07-2.09 4.07s-1.42-.794-1.42-2.402h-1.205c0 1.968 1.26 2.933 1.26 2.933s-.557.703-1.26.703V15c1.374 0 2.57-1.11 2.57-1.11s.86 1.11 2.44 1.11v-1.344c-.596 0-.94-.674-.94-.674s1.93-1.772 1.93-4.63V7.039h-2.41V6.014zM7.788 7l-2.79 8h1.64l.52-1.656H9.84L10.357 15h1.641L9.209 7H7.787zm.71 2.031l.93 3h-1.86z" />
    </svg>
  )
}

export default SvgComponent
