import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.535 2a1.02 1.02 0 00-.713.299l-.234.234c-.004.005-.01.008-.014.012L9.881 7.24l-.707.707-.707.707-1.28 1.28a2.205 2.205 0 000 3.097l.573.573-.295.294c-.411.412-.907.64-1.633 1-.725.36-1.634.854-2.568 1.788-.714.713-1.152 1.537-1.246 2.384a2.837 2.837 0 00.793 2.313c.6.6 1.465.886 2.312.79.847-.094 1.67-.53 2.383-1.243h.002c.934-.934 1.427-1.843 1.787-2.569.36-.725.589-1.221 1-1.632l.295-.295.572.572c.847.847 2.25.847 3.098 0l2.695-2.693-.002-.002 1.76-1.762 2.932-2.934c.006-.006.01-.013.015-.02l.23-.23c.396-.395.4-1.032.009-1.422l-.254-.254L16.5 2.545l-.01-.008-.248-.248A.99.99 0 0015.535 2zm-1.443 3.856c1.252.493-.13 1.84.176 2.275.264.376.976.305 1.945-.156.456-.217.767-.245.934-.182 1.186.449-2.003 2.31-.952 3.025.168.115.768.112 1.456-.039l-.182.184-1.932 1.934-4.242-4.243 2.467-2.468.004.003.297-.304.029-.03zM9.88 10.068l4.244 4.245-1.28 1.279c-.082.083-.186.083-.269 0l-1.986-1.986-1.709 1.707c-.709.708-1.04 1.48-1.377 2.158s-.676 1.31-1.41 2.045c-.459.458-.888.637-1.193.672-.306.034-.496-.039-.676-.22-.18-.18-.253-.37-.219-.675s.213-.733.672-1.191c.734-.734 1.366-1.076 2.045-1.413.678-.336 1.45-.668 2.158-1.376l1.707-1.71-1.986-1.986c-.083-.083-.083-.186 0-.27z" />
    </svg>
  )
}

export default SvgComponent
