import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M12 1L3 5v6c0 7.83 6.439 11.486 9 12 2.561-.514 9-4.17 9-12V5l-9-4zm0 2.19l7 3.11V11c0 1.836-.434 3.396-1.094 4.707C16.346 14.531 13.524 14 12 14s-4.347.53-5.906 1.707C5.434 14.396 5 12.836 5 11V6.3l7-3.11zm0 2.56c-1.792 0-3.25 1.458-3.25 3.25s1.458 3.25 3.25 3.25 3.25-1.458 3.25-3.25S13.792 5.75 12 5.75zm0 2c.689 0 1.25.561 1.25 1.25s-.561 1.25-1.25 1.25-1.25-.561-1.25-1.25.561-1.25 1.25-1.25zM12 16c2.447 0 3.978.72 4.908 1.314-1.568 2.062-3.651 3.236-4.908 3.623-1.257-.387-3.34-1.56-4.908-3.623C8.022 16.72 9.553 16 12 16z" />
    </svg>
  )
}

export default SvgComponent
