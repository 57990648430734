import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 4c-1.1 0-2 .9-2 2v4c1.1 0 2 .9 2 2s-.9 2-2 2v4c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-4c-1.1 0-2-.9-2-2s.9-2 2-2V6c0-1.1-.9-2-2-2zm0 2h16v2.537c-1.195.693-2 1.986-2 3.463s.805 2.77 2 3.463V18H4v-2.537C5.195 14.77 6 13.478 6 12s-.805-2.77-2-3.463zm8 .8l-1.4 3.3-3.6.3 2.7 2.4-.8 3.5 3.1-1.9 3.1 1.8-.8-3.4 2.7-2.4-3.6-.3z" />
    </svg>
  )
}

export default SvgComponent
