import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 3a3 3 0 00-3 3v6a3 3 0 106 0V6a3 3 0 00-3-3zm0 2c.551 0 1 .449 1 1v6c0 .551-.449 1-1 1s-1-.449-1-1V6c0-.551.449-1 1-1zm-7 7c0 3.525 2.61 6.433 6 6.92V22h2v-3.08c3.39-.487 6-3.395 6-6.92h-2a5 5 0 01-10 0z" />
    </svg>
  )
}

export default SvgComponent
