import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 3l-.553.365L3 8.963v11.236c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2V8.963zm0 2.398l7 4.64v10.161H5V10.037zM11 10.2v3H8v2h3v3h2v-3h3v-2h-3v-3z" />
    </svg>
  )
}

export default SvgComponent
