import {h} from 'preact'
import PropTypes from 'prop-types'

import './Quote.scss'
import TipIcon from '../icons/tip'

Quote.propTypes = {
  text: PropTypes.string,
}

export default function Quote({text}) {
  return (
    <p class="Quote">
      <TipIcon class="Quote-Icon" />
      <p>
        <q>{text}</q>
      </p>
    </p>
  )
}
