import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 2.73l-.615.483C11.307 3.273 9.67 4.562 8.717 6H8C6.346 6 5 7.346 5 9c-1.654 0-3 1.346-3 3 0 1.343.893 2.47 2.111 2.852l.778 5.431c.139.98.99 1.717 1.978 1.717h10.266c.989 0 1.84-.738 1.978-1.717l.778-5.431A3 3 0 0022 11.998c0-1.654-1.346-3-3-3 0-1.654-1.346-3-3-3h-.717c-.953-1.438-2.59-2.726-2.668-2.787zm0 2.583c.623.564 1.445 1.403 1.824 2.142l.28.545H16c.551 0 1 .448 1 1v2h2a1.001 1.001 0 010 2H5a1.001 1.001 0 010-2h2V9c0-.552.449-1 1-1h1.896l.28-.545c.377-.737 1.2-1.579 1.824-2.143zM6.152 15h11.694l-.713 5H6.867z" />
    </svg>
  )
}

export default SvgComponent
