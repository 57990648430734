import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 25 23"
      width={25}
      height={23}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="currentColor"
          strokeWidth={2.667}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.133 11.2h20M2.133 6.533V15.6M12.133 2.133v18.134"
        />
        <path
          d="M12.133 0a2.133 2.133 0 100 4.267 2.133 2.133 0 000-4.267zm0 18.133a2.133 2.133 0 100 4.267 2.133 2.133 0 000-4.267zM2.133 4.4a2.133 2.133 0 100 4.267 2.133 2.133 0 000-4.267zm0 9.067a2.133 2.133 0 100 4.266 2.133 2.133 0 000-4.266z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <path
          stroke="currentColor"
          strokeWidth={2.667}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M22.133 2.133v18.134"
        />
        <path
          d="M22.133 0a2.133 2.133 0 100 4.267 2.133 2.133 0 000-4.267zm0 18.133a2.133 2.133 0 100 4.267 2.133 2.133 0 000-4.267z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
