import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.813 2.884a1 1 0 011 1v2.231H20.5a1 1 0 01.832.445l.062.109 2 4a1 1 0 01.1.331l.006.116v6a1 1 0 01-.883.993l-.117.007h-1.035a3.5 3.5 0 01-6.93 0h-7.07a3.5 3.5 0 11-.302-2.001h6.65v-3.317l-12.22.001a1 1 0 01-.992-.873l-.008-.115L.5 3.896a1 1 0 011-1.012zM4 16.116a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm14 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm3.499-3.318h-5.687v2.085a3.484 3.484 0 011.996-.762l.192-.005a3.5 3.5 0 013.163 1.999h.337l-.001-3.317zm-1.618-4.682h-4.068v2.682h5.409l-1.341-2.682zm-6.068-3.232H2.512l.069 5.915h11.232V4.884z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
