import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor">
        <path d="M23.493 3.382l.007.118v5.844c0 1.14-.882 2.072-2.001 2.15l-.154.006H20.5a1 1 0 01-.89-1.458l.058-.097L21.5 7.196V5.024l-5.869 2.55 1.184 1.1.108.118c.332.427.53.941.57 1.478l.007.202-.001.028h.001v3l-.006.125a4.014 4.014 0 013 3.67l.006.205a4 4 0 01-4 4h-12a4.006 4.006 0 01-3.994-3.795L.5 17.5a4 4 0 014-4v-2a2 2 0 011.85-1.995L6.5 9.5h1l-.008-3.39c.01-.843.66-1.53 1.484-1.604l.147-.006H11.5c.605 0 1.178.254 1.584.694l.048.057.9.837 8.07-3.505a1 1 0 011.391.8zM16.5 15.5h-12c-1.105 0-2 .895-2 1.971l.003.147A2 2 0 004.5 19.5h12c1.105 0 2-.895 2-1.971l-.003-.147A2 2 0 0016.5 15.5zm-1-4h-9v2h9v-2zm-4-5H9.492L9.5 9.498 8.3 9.5h6.465l-3.034-2.822-.108-.117a.157.157 0 00-.076-.054L11.5 6.5z" />
        <path d="M16.5 16.5a1 1 0 100 2 1 1 0 000-2zm-12 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2zm4 0a1 1 0 100 2 1 1 0 000-2z" />
      </g>
    </svg>
  )
}

export default SvgComponent
