import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M16.568 2a2.99 2.99 0 012.26 1.02l.126.154.114.161.044.073.18.314 1.29 2.5.04.105.018.067.052.107c.07.155.128.314.174.478l.06.25.044.256.024.253.006.206V18.49c0 1.794-1.342 3.298-3.072 3.49l-.18.016-.187.005H6.44c-1.758 0-3.23-1.37-3.419-3.137l-.015-.184L3 18.49V7.944c0-.41.072-.814.216-1.204l.094-.233.025-.051.106-.242 1.315-2.551v.005l.067-.131A2.955 2.955 0 016.87 2.053l.189-.03.2-.018L7.433 2h9.136zm.993 4.2H6.44c-.848 0-1.563.64-1.688 1.47l-.016.14-.005.134V18.49c0 .866.627 1.595 1.44 1.724l.137.015.132.005h11.12c.848 0 1.563-.64 1.688-1.47l.016-.14.005-.134V7.944c0-.96-.768-1.744-1.709-1.744zm-9.51 9.296a1.72 1.72 0 110 3.442 1.72 1.72 0 01-.001-3.442zm3.913-3.913a1.72 1.72 0 110 3.442 1.72 1.72 0 01-.001-3.442zm3.913-3.913a1.72 1.72 0 110 3.442 1.72 1.72 0 01-.001-3.442zm.691-3.903H7.432c-.424 0-.785.175-1.008.475l-.069.105-.063.12-.005.023-.183.353.102-.012.233-.009H17.56c.067 0 .133.002.199.007l.156.016-.206-.399a1.236 1.236 0 00-1.006-.673l-.136-.006z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SvgComponent
