import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M7 3a1 1 0 00-.781.375l-4 5A1 1 0 002 9v8a1 1 0 001 1h.05c.248 1.685 1.7 3 3.45 3 1.75 0 3.202-1.315 3.45-3h4.1c.248 1.685 1.7 3 3.45 3 1.75 0 3.202-1.315 3.45-3H22a1 1 0 001-1v-4.693c0-.556-.155-1.1-.445-1.573v-.002l-2.037-3.306A3.003 3.003 0 0017.963 6H14V4a1 1 0 00-1-1H7zm2 2h3v11H9.645c-.567-1.175-1.762-2-3.145-2-.977 0-1.863.41-2.5 1.064V9.352l3-3.75V10h2V5zm5 3h3.963c.348 0 .669.178.851.475l2.038 3.306c.097.16.148.342.148.526V16h-.355c-.567-1.175-1.762-2-3.145-2s-2.578.825-3.145 2H14V8zm-7.5 8c.84 0 1.5.66 1.5 1.5S7.34 19 6.5 19 5 18.34 5 17.5 5.66 16 6.5 16zm11 0c.84 0 1.5.66 1.5 1.5s-.66 1.5-1.5 1.5-1.5-.66-1.5-1.5.66-1.5 1.5-1.5z" />
    </svg>
  )
}

export default SvgComponent
