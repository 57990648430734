import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.155 2.5c.995 0 1.976.487 2.47 1.232l.093.153 4.066 6.474a4.24 4.24 0 01.522 1.88l.006.253V13.5h-.001l.001 2.052a3.49 3.49 0 01.963 1.948h.367a4.366 4.366 0 113.261 2l-3.93.001a3.5 3.5 0 01-6.325 0H6.973a3.5 3.5 0 11-5.662-3.95V4.417c-.001-1.074.794-1.844 1.855-1.912L3.32 2.5h3.835zm-3.345 14a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm8 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm7.513-3.71a2.366 2.366 0 00-.3 4.711l.113-.001v.013a2.366 2.366 0 10.187-4.723zM5.512 11.5H3.31v3.035A3.5 3.5 0 017.276 17.5h1.07a3.5 3.5 0 013.966-2.964L12.31 13.5H8.465a1 1 0 01-.499-.133l-.109-.073L5.512 11.5zM3.31 4.498V9.5h2.54a1 1 0 01.498.133l.11.073L8.803 11.5h3.318l-.06-.126L7.994 4.9c-.102-.186-.398-.357-.719-.393l-.12-.007H3.32l-.01-.002z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
