import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.273 4.013v1.16a6.003 6.003 0 014.51 5.59l.005.224-.001 3.972h.295V5.987a1 1 0 01.883-.993l.117-.007h5.687a1 1 0 01.832.445l.062.108 2 4a1 1 0 01.1.332l.006.115v6a1 1 0 01-.883.994l-.117.006h-1.035a3.5 3.5 0 01-6.933-.027h-4.564a3.5 3.5 0 01-6.954-.156H1.277a1 1 0 01-.992-.874l-.007-.116-.047-4.827a6.003 6.003 0 014.12-5.7V4.014h4.922zM6.769 14.987a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm11.5 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm3.5-4h-5.688v2.768a3.484 3.484 0 011.996-.762l.192-.006a3.5 3.5 0 013.163 2h.337v-4zm-13.981-4H6.23a4 4 0 00-3.995 3.8l-.005.24.036 3.776H3.7a3.5 3.5 0 016.219.156h1.868v-3.972a4 4 0 00-3.8-3.995l-.2-.005zm12.362 0h-4.068l-.001 2h5.068l-1-2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
