import {h, Fragment} from 'preact'
import PropTypes from 'prop-types'

import './Gears.scss'
import GearOneIcon from './icons/gear-one'
import GearTwoIcon from './icons/gear-two'
import GearThreeIcon from './icons/gear-three'
import GearFourIcon from './icons/gear-four'
import GearFiveIcon from './icons/gear-five'
import GearSixIcon from './icons/gear-six'
import Block from './common/Block'
import CircleIcon from './common/CircleIcon'

Gears.propTypes = {
  types: PropTypes.array,
  typesData: PropTypes.array,
}

const gearsMap = {
  'Manual Transmission (Synchromesh) - Similar to car or modern bus': (
    <GearOneIcon />
  ),
  'Manual Transmission (Non-synchromesh) - Matching the speed of gears when shifting': (
    <GearTwoIcon />
  ),
  'Automated Manual - Tiptronic etc': <GearThreeIcon />,
  Automatic: <GearFourIcon />,
  'Road Ranger': <GearFiveIcon />,
  'Split-Shift Manual (Synchromesh)': <GearSixIcon />,
}

const otherType = 'Other'

const GearBox = ({type}) => {
  const typeParts = type.split(' - ')
  return (
    <li class="Gears-Item">
      <div>
        <h3 class="Gears-Title">{typeParts[0]}</h3>
        {typeParts[1] && <p class="Gears-Label">{typeParts[1]}</p>}
      </div>
      <CircleIcon className="Gears-Icon" src={gearsMap[type]} />
    </li>
  )
}

export default function Gears({types}) {
  if (!types) return null

  const other = types.find((type) => type.value === otherType)
  return (
    <Block class="Gears" title="Gearboxes experience">
      <ul class="Gears-List">
        {types
          .filter((type) => type.value !== otherType)
          .map((type) => (
            <GearBox key={type.value} type={type.value} />
          ))}
      </ul>
      {other && (
        <Fragment>
          <h4 class="Gears-Other">Other</h4>
          {other.additionalValues && (
            <p class="Gears-Title">{other.additionalValues[0]}</p>
          )}
        </Fragment>
      )}
    </Block>
  )
}
