import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.5 3a3 3 0 012.995 2.824L17.5 6v1h1a1 1 0 01.282.04l.882-1.885a2 2 0 011.648-1.148L21.477 4H23.5v2l-2.024.002L19.5 10.22V13h1a1 1 0 01.906.576l.043.108 1 3a1 1 0 01.033.507l-.033.125-1 3a1 1 0 01-.833.677L20.5 21h-18a1 1 0 01-.906-.576l-.043-.108-1-3a1 1 0 01-.033-.507l.033-.125 1-3a1 1 0 01.833-.677L2.5 13h1V8a1 1 0 01.883-.993L4.5 7h1V6a3 3 0 012.65-2.98l.174-.015L8.5 3h6zm5.279 12H3.22l-.666 1.999L3.22 19h16.559l.667-2.001L19.779 15zM17.5 9h-12v4h12V9zm-9 1a1 1 0 110 2 1 1 0 010-2zm6 0a1 1 0 110 2 1 1 0 010-2zm0-5h-6a1 1 0 00-.993.883L7.5 6v1h8V6a1 1 0 00-.883-.993L14.5 5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
