import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={27}
      viewBox="0 0 21 27"
      width={21}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M.471 22.908l3.9 3.381c.45.39 1.024.403 1.283.028a.754.754 0 00.126-.433V14.023a3.554 3.554 0 00-1.217-2.677L.376 7.69A.226.226 0 000 7.86v14.123c.004.152.045.317.126.48.082.167.201.32.344.445z"
          fill="#000"
        />
        <path
          d="M20.69 1.028v13.027c0 .685-.555 1.402-1.24 1.6L.014 21.673V7.03c0-.685.555-1.402 1.24-1.6L19.45.147c.685-.199 1.24.195 1.24.88zM9.488 7.885a.849.849 0 00-.47.01c-.519.151-.94.694-.94 1.213v4.5c0 .164.044.314.126.433.26.374.835.361 1.284-.029l3.9-3.381c.143-.124.261-.277.344-.445.26-.524.105-1.054-.344-1.183z"
          fill="#323232"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
