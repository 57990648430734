import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9 3c-1.093 0-2 .907-2 2v3H6c-.552 0-1-.449-1-1V4.184A2.997 2.997 0 003 7v13c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V10c0-1.103-.897-2-2-2V5c0-1.093-.907-2-2-2zm0 2h2v3H9zm4 0h4v3h-4zM5 9.816c.314.113.648.184 1 .184h13v10H5zM16 14a1 1 0 100 2 1 1 0 000-2z" />
    </svg>
  )
}

export default SvgComponent
