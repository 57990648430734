import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16.002 2.005a1.99 1.99 0 00-1.459.785l-2.53 3.37h-5.24L2 21.67l15.512-4.773v-5.269L20.71 8.96c.96-.72 1.062-2.168.215-3.015l-3.37-3.37a1.988 1.988 0 00-1.554-.57zm.14 1.987l3.37 3.367-.02.015-2.92 2.432-2.74-2.74zM8.25 8.159h3.848l3.414 3.415v3.847l-8.448 2.6 2.932-2.932a2 2 0 10-1.416-1.412l-2.93 2.93z" />
    </svg>
  )
}

export default SvgComponent
