import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15 3v8h-2l-1-3-4 3-1-3-4 3v11h18V3zm2 2h2v2h-2zm0 4h2v11h-2v-1h-2v1h-2v-1h-2v1H9v-1H7v1H5v-8l.98-.734.124.367.876 2.633L9.2 12.6l1.78-1.334.124.367.455 1.367H17zM7 15v2h2v-2zm4 0v2h2v-2zm4 0v2h2v-2z" />
    </svg>
  )
}

export default SvgComponent
