import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.95 9.39a1.333 1.333 0 011.66.894l.915 3.049h1.15c.686 0 1.25.52 1.318 1.196l.007.138c0 .736-.591 1.333-1.325 1.333h-.35l1.2 4.004a4 4 0 11-2.553.766l-.642-2.143c-.103.026-.21.04-.32.04L20 18.666v2a4.667 4.667 0 01-4.452 4.663l-.213.004h-4.896A4.002 4.002 0 012.667 24a4 4 0 017.772-1.333h4.896a2 2 0 001.884-1.333h-3.888a2.667 2.667 0 01-2.664-2.667H3.99a1.326 1.326 0 01-1.324-1.334v-2.668c0-.735.59-1.332 1.324-1.332h2.02c.731 0 1.324.59 1.324 1.332V16h6a2.668 2.668 0 012.664-2.667h4.744l-.685-2.283a1.334 1.334 0 01.766-1.615zm4.383 13.277a1.333 1.333 0 100 2.666 1.333 1.333 0 000-2.666zm-18.666 0a1.333 1.333 0 100 2.666 1.333 1.333 0 000-2.666z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgComponent
