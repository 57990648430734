import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 2c-1.094 0-2 .906-2 2v16c0 1.094.906 2 2 2h12c1.094 0 2-.906 2-2V8l-6-6zm0 2h7v5h5v11H6zm6 7l-4 3h1v4h2v-2h2v2h2v-4h1z" />
    </svg>
  )
}

export default SvgComponent
