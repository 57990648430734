import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M3 5s-2 6-2 9a5 5 0 005 5h12a5 5 0 005-5c0-3-2-9-2-9H3zm1.469 2h15.064C20.151 9.061 21 12.272 21 14c0 1.654-1.346 3-3 3H6c-1.654 0-3-1.346-3-3 0-1.724.85-4.938 1.469-7zM7 9v2H5v2h2v2h2v-2h2v-2H9V9H7zm9 0a1 1 0 100 2 1 1 0 000-2zm2 4a1 1 0 100 2 1 1 0 000-2z" />
    </svg>
  )
}

export default SvgComponent
