import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M12 2C6.489 2 2 6.489 2 12s4.489 10 10 10 10-4.489 10-10S17.511 2 12 2zm0 2c1.853 0 3.55.63 4.904 1.682L16 6.586 17.414 8l.904-.904A7.954 7.954 0 0120 12c0 4.43-3.57 8-8 8-1.853 0-3.55-.63-4.904-1.682L8 17.414 6.586 16l-.904.904A7.954 7.954 0 014 12c0-4.43 3.57-8 8-8zm0 3c-2.75 0-5 2.25-5 5s2.25 5 5 5 5-2.25 5-5-2.25-5-5-5zm0 2c1.669 0 3 1.331 3 3s-1.331 3-3 3-3-1.331-3-3 1.331-3 3-3zm0 2a1 1 0 100 2 1 1 0 000-2z" />
    </svg>
  )
}

export default SvgComponent
