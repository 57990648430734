import {h, Fragment} from 'preact'
import PropTypes from 'prop-types'

import './Hobby.scss'
import ArtsIcon from './industry-icons/arts'
import BakingIcon from './hobby-icons/baking'
import CampingIcon from './hobby-icons/camping'
import CardsIcon from './hobby-icons/cards'
import BoardgamesIcon from './hobby-icons/boardgames'
import CookingIcon from './hobby-icons/cooking'
import DancingIcon from './hobby-icons/dancing'
import FishingIcon from './hobby-icons/fishing'
import FitnessIcon from './hobby-icons/fitness'
import GamesIcon from './hobby-icons/games'
import HikingIcon from './hobby-icons/hiking'
import KaraokeIcon from './hobby-icons/karaoke'
import LanguagesIcon from './hobby-icons/languages'
import MoviesIcon from './hobby-icons/movies'
import PaintingIcon from './hobby-icons/painting'
import PhotoIcon from './hobby-icons/photo'
import PodcastIcon from './hobby-icons/podcast'
import SporttIcon from './hobby-icons/sport'
import VolunteeringIcon from './hobby-icons/volunteering'
import WritingIcon from './hobby-icons/writing'
import BooksIcon from './hobby-icons/books'
import MusicIcon from './hobby-icons/music'
import TravelIcon from './hobby-icons/travel'
import Block from './common/Block'
import CircleIcon from './common/CircleIcon'

Hobby.propTypes = {
  hobby: PropTypes.array,
  knowledge: PropTypes.array,
  sport: PropTypes.array,
}

const hobbyMap = {
  Baking: <BakingIcon class="CircleIcon-Icon" />,
  Cooking: <CookingIcon class="CircleIcon-Icon" />,
  Dancing: <DancingIcon class="CircleIcon-Icon" />,
  'Video games': <GamesIcon class="CircleIcon-Icon" />,
  Fishing: <FishingIcon class="CircleIcon-Icon" />,
  Hiking: <HikingIcon class="CircleIcon-Icon" />,
  Karaoke: <KaraokeIcon class="CircleIcon-Icon" />,
  Crafts: <ArtsIcon class="CircleIcon-Icon" />,
  Movies: <MoviesIcon class="CircleIcon-Icon" />,
  Books: <BooksIcon class="CircleIcon-Icon" />,
  Music: <MusicIcon class="CircleIcon-Icon" />,
  Podcasts: <PodcastIcon class="CircleIcon-Icon" />,
  Photography: <PhotoIcon class="CircleIcon-Icon" />,
  Travelling: <TravelIcon class="CircleIcon-Icon" />,
  Fitness: <FitnessIcon class="CircleIcon-Icon" />,
  Painting: <PaintingIcon class="CircleIcon-Icon" />,
  Volunteering: <VolunteeringIcon class="CircleIcon-Icon" />,
  Languages: <LanguagesIcon class="CircleIcon-Icon" />,
  Camping: <CampingIcon class="CircleIcon-Icon" />,
  Writing: <WritingIcon class="CircleIcon-Icon" />,
  Sports: <SporttIcon class="CircleIcon-Icon" />,
  Cards: <CardsIcon class="CircleIcon-Icon" />,
  'Board games': <BoardgamesIcon class="CircleIcon-Icon" />,
}

const otherValue = 'Other'

export default function Hobby({hobby, knowledge, sport}) {
  if (!hobby && !knowledge && !sport) return null

  const other = hobby ? hobby.find((item) => item.value === otherValue) : null
  const hobbies =
    hobby && other ? hobby.filter((item) => item.value !== otherValue) : hobby

  return (
    <Block className="Hobby" title="Hobby">
      {hobbies && (
        <ul class="Hobby-List">
          {hobbies.map(({value}) => (
            <li key={value} class="Hobby-Item">
              <CircleIcon icon={hobbyMap[value]} />
              <p class="Hobby-Label">{value}</p>
            </li>
          ))}
        </ul>
      )}
      {other && (
        <p class="Hobby-Title Hobby-Title_other">
          Other: {other.additionalValues ? other.additionalValues[0] : '...'}
        </p>
      )}
      {sport && (
        <Fragment>
          <h3 class="Hobby-Title">Sport</h3>
          <p class="Hobby-Text">{sport[0].value}</p>
        </Fragment>
      )}
      {knowledge && (
        <Fragment>
          <h3 class="Hobby-Title">Knowledge</h3>
          <ul class="Hobby-Sublist">
            {knowledge.map(({field, value}) => (
              <li key={field} class="Hobby-SubItem">
                {value}
              </li>
            ))}
          </ul>
        </Fragment>
      )}
    </Block>
  )
}
