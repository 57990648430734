import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M24.67 6.053c.735 0 1.493.57 1.695 1.279l1.561 5.779h1.407c.736 0 1.334.598 1.334 1.337v10.215c0 .738-.601 1.337-1.334 1.337h-1.56a4.002 4.002 0 01-7.545 0h-4.895V13.997c0-.49.404-.886.897-.886h8.912L24.007 8.72h-7.372c-.186-.01-1.257-.116-1.257-1.333 0-1.276 1.175-1.331 1.278-1.334h8.015zM12.444 6c1.089 0 2.001.848 2.001 1.963V26h-3.116a4.002 4.002 0 01-7.545 0h-.672a.293.293 0 01-.06-.006l-.382-.003a1.333 1.333 0 110-2.667h1.117a4.002 4.002 0 017.542.01h.45V8.67l-9.111-.003a1.333 1.333 0 010-2.667zM24 23.333A1.333 1.333 0 1024 26a1.333 1.333 0 000-2.667zm-16.444 0a1.333 1.333 0 100 2.667 1.333 1.333 0 000-2.667zM28 15.77l-.518.001a1.317 1.317 0 01-.143.008L19 15.777l-.999.002.007 7.554h2.22a4.002 4.002 0 017.545 0H28V15.77z" />
        <path d="M15.378 7.54l2.4.264v9.974h-2.49z" />
      </g>
    </svg>
  )
}

export default SvgComponent
