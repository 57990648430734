import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
        <filter id="prefix__b">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        filter="url(#prefix__a)"
        transform="translate(-170 -837)"
        fill="none"
        fillRule="evenodd"
      >
        <g filter="url(#prefix__b)" transform="translate(170 837)">
          <path
            fill="#000"
            fillRule="nonzero"
            d="M4 2c-1.093 0-2 .907-2 2v14c0 1.093.907 2 2 2h10.037v-2H4V4h14v8.006h2V4c0-1.093-.907-2-2-2H4zm4 4v2h6V6H8zm-1 6l-1 1v3h2v-3l-1-1zm3 0l-1 1v3h2v-3l-1-1zm11.402 1.55l-6.004 7.999 1.2.9 6.004-7.998-1.2-.9zM16.5 14a1.5 1.5 0 10.001 3.001A1.5 1.5 0 0016.5 14zm5 5a1.5 1.5 0 10.001 3.001A1.5 1.5 0 0021.5 19z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
