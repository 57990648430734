import {h, Fragment} from 'preact'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {format, parseISO, differenceInMonths} from 'date-fns'

import './Employment.scss'
import ItIcon from './industry-icons/it'
import ManufacIcon from './industry-icons/manufac'
import HealthIcon from './industry-icons/health'
import FinanceIcon from './industry-icons/finance'
import RetailIcon from './industry-icons/retail'
import AccountIcon from './industry-icons/account'
import ConstructionIcon from './industry-icons/construction'
import MediaIcon from './industry-icons/media'
import RestaurantIcon from './industry-icons/restaurant'
import AgricultureIcon from './industry-icons/agriculture'
import RlectricityIcon from './industry-icons/electricity'
import WasteIcon from './industry-icons/waste'
import TransportIcon from './industry-icons/transport'
import WarehouseIcon from './industry-icons/warehouse'
import RentalIcon from './industry-icons/rental'
import SupportIcon from './industry-icons/support'
import AdminsIcon from './industry-icons/admins'
import EducationIcon from './industry-icons/education'
import ArtsIcon from './industry-icons/arts'
import HospitalityIcon from './industry-icons/hospitality'
import MarketingIcon from './industry-icons/marketing'
import Block from './common/Block'

Employment.propTypes = {
  history: PropTypes.array,
  industry: PropTypes.array,
}

const formatDate = (date) => format(parseISO(date), 'MMM yyyy')

const renderLastMonth = (timeTo) => (timeTo ? formatDate(timeTo) : 'now')

const industryMap = {
  'Information technology': <ItIcon />,
  Manufacturing: <ManufacIcon />,
  'Health Care and Social Assistance': <HealthIcon />,
  'Financial and Insurance Services': <FinanceIcon />,
  Retail: <RetailIcon />,
  'Accounting and legal services': <AccountIcon />,
  'Construction, repair and maintenance': <ConstructionIcon />,
  'Information Media and Telecommunications': <MediaIcon />,
  'Restaurants, bars and food services': <RestaurantIcon />,
  'Agriculture, Forestry, Fishing': <AgricultureIcon />,
  'Electricity, Gas, Water': <RlectricityIcon />,
  'Waste Services': <WasteIcon />,
  Transport: <TransportIcon />,
  Warehousing: <WarehouseIcon />,
  'Rental, Hiring and Real Estate Services': <RentalIcon />,
  'Administrative and Support Services': <SupportIcon />,
  'Public Administration and Safety': <AdminsIcon />,
  'Education and Training': <EducationIcon />,
  'Creative Arts': <ArtsIcon />,
  'Recreation, hospitality & tourism': <HospitalityIcon />,
  'Marketing & Advertisement': <MarketingIcon />,
}

const today = new Date()

export default function Employment({history, industry}) {
  if (!history && !industry) return null

  const timeline = []
  const histories = []
  if (history) {
    history.forEach((historyItem) => {
      const row = histories.find(
        (historiesItem) => historiesItem.row === historyItem.row
      )
      if (row) {
        row[historyItem.field] = historyItem.value
      } else {
        histories.push({
          row: historyItem.row,
          [historyItem.field]: historyItem.value,
        })
      }
    })
    histories.sort((a, b) => parseISO(b.timeFrom) - parseISO(a.timeFrom))
    histories.forEach((item, index) => {
      const dateFrom = parseISO(item.timeFrom)
      const dateTo = item.timeTo ? parseISO(item.timeTo) : today
      timeline.push(Math.abs(differenceInMonths(dateTo, dateFrom)))
      const nextItem = histories[index + 1]
      nextItem &&
        timeline.push(
          Math.abs(differenceInMonths(dateFrom, parseISO(nextItem.timeTo)))
        )
    })
  }

  const step = history ? 100 / timeline.reduce((a, b) => a + b, 0) : null

  return (
    <Block className="Employment" title="Employment">
      {history && (
        <div class="Employment-Block">
          <p className="Employment-TimeLabel Employment-TimeLabel_to">
            {histories[0].timeTo && renderLastMonth(histories[0].timeTo)}
          </p>
          <p className="Employment-TimeLabel Employment-TimeLabel_from">
            {histories[histories.length - 1].timeFrom &&
              formatDate(histories[histories.length - 1].timeFrom)}
          </p>
          <div className="Employment-Periods">
            {timeline.map((period, index) => (
              <div
                className={cn('Employment-Period', {
                  'Employment-Period_now': index === 0 && !histories[0].timeTo,
                })}
                style={{height: `${step * period}%`}}
              />
            ))}
          </div>
          <ul>
            {histories.map((history) => (
              <li key={history.row} class="Employment-Item">
                <h3 class="Employment-Title">
                  {history.timeFrom &&
                    history.timeTo &&
                    formatDate(history.timeFrom)}{' '}
                  - {renderLastMonth(history.timeTo)}
                </h3>
                {history.role && <p class="Employment-Text">{history.role}</p>}
                {history.company && (
                  <p class="Employment-Subtext">{history.company}</p>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      {industry && (
        <Fragment>
          <ul class="Employment-Icons">
            {industry.map(
              ({value}) =>
                industryMap[value] && (
                  <li key={value} class="Employment-Icon">
                    {industryMap[value]}
                  </li>
                )
            )}
          </ul>
          <h4 class="Employment-Subtitle">industries</h4>
          <ul class="Employment-Labels">
            {industry.map((item) => (
              <li key={item.value} class="Employment-Label">
                {item.value}
                {item.additionalValues && `: ${item.additionalValues[0]}`}
              </li>
            ))}
          </ul>
        </Fragment>
      )}
    </Block>
  )
}
