import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M12 1L0 6l4 1.668v5.1A3 3 0 003 15c0 1.276.823 2.397 1.992 2.816C6.396 21.028 9.043 23 12 23s5.604-1.972 7.008-5.184A3.008 3.008 0 0021 15a3 3 0 00-1-2.232v-5.1l2-.834V12h2V6L12 1zm0 2.168L18.8 6l-.8.332v2.84A2.963 2.963 0 0017 9H7c-.35 0-.687.06-1 .172v-2.84L5.2 6 12 3.168zM7 11h10a1 1 0 011 1v1.852l.502.287A.982.982 0 0119 15a.996.996 0 01-.863.98l-.584.082-.211.551C16.302 19.32 14.256 21 12 21s-4.302-1.682-5.34-4.387l-.21-.55-.587-.08A1.001 1.001 0 015 15c0-.36.186-.682.498-.861L6 13.852V12a1 1 0 011-1z" />
    </svg>
  )
}

export default SvgComponent
