import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M11 3v2.045c-2.651.204-4.408 1.199-5.457 2.248A6.283 6.283 0 004.332 9H2v2h1c.565 0 1 .435 1 1v7c0 1.645 1.355 3 3 3h10c1.645 0 3-1.355 3-3v-7c0-.565.435-1 1-1h1V9h-2.332a6.306 6.306 0 00-1.211-1.707c-1.05-1.05-2.806-2.044-5.457-2.248V3h-2zm1 4c2.778 0 4.22.884 5.043 1.707.103.103.167.195.25.293H6.707c.083-.098.147-.19.25-.293C7.78 7.884 9.222 7 12 7zm-6.188 4h12.375A2.948 2.948 0 0018 12v7c0 .565-.435 1-1 1H7c-.565 0-1-.435-1-1v-7c0-.352-.074-.685-.188-1z" />
    </svg>
  )
}

export default SvgComponent
