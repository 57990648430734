import {h} from 'preact'
import PropTypes from 'prop-types'

import './Goals.scss'
import GoalIcon from './icons/goal'
import Block from './common/Block'

Goals.propTypes = {
  primary: PropTypes.array,
}

export default function Goals({primary}) {
  if (!primary) return null

  return (
    <Block zoomEffect className="Goals">
      <GoalIcon class="Goals-Icon" />
      <h2 class="Goals-Title">goals</h2>
      <p class="Goals-Text">{primary[0].value}</p>
    </Block>
  )
}
