import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M8.87 1a3.944 3.944 0 00-3.93 3.928V11H3V7H1v6h3.941v10h2v-.531l5-4.168 5 4.168V23h2V13h3.942V7h-2v4H18.94V4.928A3.94 3.94 0 0015.014 1H8.869zm0 2h6.144c1.075 0 1.927.851 1.927 1.928V11h-10V4.928C6.941 3.85 7.793 3 8.87 3zm-.167 10h6.477l-3.239 2.7L8.703 13zm-1.762 1.135L10.38 17l-3.44 2.865v-5.73zm10 0v5.73L13.504 17l3.437-2.865z" />
    </svg>
  )
}

export default SvgComponent
