import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor">
        <path d="M18 15.5a3 3 0 100 6 3 3 0 000-6zm0 4.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25zM5 15.5a3 3 0 100 6 3 3 0 000-6zm0 4.25c-.689 0-1.25-.561-1.25-1.25s.561-1.25 1.25-1.25 1.25.561 1.25 1.25-.561 1.25-1.25 1.25zm9-6.25h8l-1-2h-7v2z" />
        <path d="M20 16.5h2v2h-2z" />
        <path d="M20.276 8.053a.998.998 0 00-.894-.553H14v11h2v-9h2.764L21 13.972V18.5h2v-5l-2.724-5.447z" />
        <path d="M3.503 2.5h7.943l4.514 3.952V16.5c0 1.085-.907 1.924-1.996 1.995l-.15.005H6.918v-2h6.896l.056-.003.07-.012c.006 0 .012 0 .015.002l.005.013V7.359L10.694 4.5H3.503l-.056.003-.07.012c-.006 0-.012 0-.015-.002L3.357 4.5v12c0-.014.006-.017.02-.015l.07.012.056.003v2c-1.105 0-2.06-.792-2.14-1.854l-.006-.146v-12c0-1.085.907-1.924 1.996-1.995l.15-.005z" />
      </g>
      <path
        d="M8.266 6.5c-.459 0-.885.256-1.102.68l-.003.006-.185.389a.44.44 0 00.013.43c.08.131.22.205.367.194a.402.402 0 00.336-.248l.178-.372a.445.445 0 01.396-.237h.796c.174 0 .326.098.397.238l.004.007.638 1.14-.545.327c-.155.093-.126.336.046.386l1.463.421a.199.199 0 00.246-.147l.4-1.537c.048-.181-.137-.331-.292-.239l-.622.373-.631-1.128A1.239 1.239 0 009.062 6.5h-.796zM6.579 9.029l-1.497.251c-.176.03-.23.27-.086.38l.542.411-.737 1.31-.004.007c-.211.415-.178.925.085 1.307l.291.422c.235.343.616.54 1.018.54h.86a.397.397 0 00.352-.209.44.44 0 000-.424.397.397 0 00-.352-.21h-.86a.449.449 0 01-.368-.19l-.292-.422a.395.395 0 01-.027-.415l.686-1.22.543.412c.144.11.343-.018.315-.204l-.24-1.572a.202.202 0 00-.23-.175zm5.264 1.677a.4.4 0 00-.347.235.44.44 0 00.036.433l.283.42a.404.404 0 01-.03.41h.002l-.293.424a.45.45 0 01-.368.188h-1.27v-.628c0-.19-.22-.286-.348-.151l-.999 1.049a.221.221 0 000 .302l.998 1.049c.128.135.348.04.348-.152v-.627h1.27c.4 0 .782-.194 1.018-.537l.293-.425v-.001a1.29 1.29 0 00.083-1.304l-.011-.023-.323-.479a.395.395 0 00-.343-.183z"
        stroke="#000"
        strokeWidth={0.5}
      />
    </svg>
  )
}

export default SvgComponent
