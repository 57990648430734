import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M24.67 10c.735 0 1.493.57 1.695 1.278l1.54 5.388h1.428c.736 0 1.334.599 1.334 1.338v6.659c0 .738-.601 1.337-1.334 1.337h-1.56a4.002 4.002 0 01-7.545 0h-4.895v-8.438c0-.495.404-.895.897-.895h8.912l-1.135-4h-7.344s-1.285 0-1.285-1.334c0-1.217 1.071-1.323 1.257-1.332L24.671 10zM12.444 6c1.089 0 2.001.848 2.001 1.963V26h-3.116a4.002 4.002 0 01-7.545 0h-.672a.294.294 0 01-.058-.006l-.384-.003a1.333 1.333 0 110-2.667h1.117a4.002 4.002 0 017.542.01h.45V8.67l-9.111-.003a1.333 1.333 0 010-2.667zM24 23.333A1.333 1.333 0 1024 26a1.333 1.333 0 000-2.667zm-16.444 0a1.333 1.333 0 100 2.667 1.333 1.333 0 000-2.667zM28 19.324l-.518.002a1.317 1.317 0 01-.143.007H19l-.999.002.007 3.998h2.22a4.002 4.002 0 017.545 0H28v-4.009z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default SvgComponent
