import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 32 32"
      width={32}
      height={32}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="currentColor"
          strokeWidth={2.667}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.466 15.867h20M18.8 6.8v18.134"
        />
        <path
          d="M18.8 4.667a2.134 2.134 0 100 4.267 2.134 2.134 0 000-4.267zm0 18.133a2.134 2.134 0 100 4.267 2.134 2.134 0 000-4.267z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <path
          stroke="currentColor"
          strokeWidth={2.667}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12.133 6.8v18.134M5.466 6.8v9.067"
        />
        <path
          d="M12.133 4.667a2.134 2.134 0 100 4.267 2.134 2.134 0 000-4.267zm-6.667 0a2.134 2.134 0 100 4.267 2.134 2.134 0 000-4.267zM12.133 22.8a2.134 2.134 0 100 4.267 2.134 2.134 0 000-4.267z"
          fill="currentColor"
          fillRule="nonzero"
        />
        <path
          stroke="currentColor"
          strokeWidth={2.667}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M25.466 6.8v18.134"
        />
        <path
          d="M25.466 4.667a2.134 2.134 0 100 4.267 2.134 2.134 0 000-4.267zm0 18.133a2.134 2.134 0 100 4.267 2.134 2.134 0 000-4.267z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
