import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.227 3.933a1 1 0 011 1V11h.877V6.17a1 1 0 011-1h1.999a1 1 0 011 1L11.102 11H12V8a1 1 0 01.883-.993L13 7h4.963a3 3 0 012.447 1.264l.107.162 2.038 3.308c.255.414.404.882.438 1.365l.007.207V17a1 1 0 01-.883.993L22 18h-1.035a3.5 3.5 0 01-6.93 0h-5.07a3.5 3.5 0 01-6.93 0H1v-2h1.337a3.5 3.5 0 016.326 0H12v-3H1v-2h1.229V4.933a1 1 0 011-1h1.998zM17.5 16a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-12 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm12.463-7H14v7h.337a3.501 3.501 0 012.971-1.995L17.5 14a3.5 3.5 0 013.163 2H21v-2.694c0-.139-.029-.276-.084-.402l-.064-.122-2.037-3.307a1 1 0 00-.723-.467L17.963 9z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
