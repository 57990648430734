import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zM9.162 4.533L12 5.725l2.838-1.192a8.06 8.06 0 013.328 2.383l-.191 3.162 2.007 2.281a7.936 7.936 0 01-1.263 3.96l-3.074.745-1.551 2.647A7.924 7.924 0 0112 20a7.877 7.877 0 01-2.094-.29l-1.55-2.646-3.075-.746a7.93 7.93 0 01-1.263-3.959l2.007-2.28-.191-3.163a8.06 8.06 0 013.328-2.383zM12 8.43l-3.758 2.724 1.502 4.416h4.512l1.502-4.416z" />
    </svg>
  )
}

export default SvgComponent
