import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18 4a2 2 0 10-.001 3.999A2 2 0 0018 4zm-7.99.877L5.553 7.105l.894 1.79L9.99 7.123l2.063 1.076-2.278 2.172a2.496 2.496 0 00-.64 2.615L3 19.586 4.414 21l6.34-6.34 1.98 1.23-3.437 3.4 1.406 1.42 4.754-4.698-2.418-3.05 1.93-1.802 1.572 3.145 4.87-2.186-.821-1.826-3.12 1.402-1.976-3.953z" />
    </svg>
  )
}

export default SvgComponent
