import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2zm0 2h2v6l3-1.5 3 1.5V4h4v12H6zm4 0h2v2.764l-.105-.053L11 6.264l-.895.447-.105.053zM4 20v2h16v-2z" />
    </svg>
  )
}

export default SvgComponent
