import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.5 3v2h-1.523l3.205 8.012.118-.007.2-.005c.346 0 .68.044 1 .126V3h2l.001 11.355c.622.705.999 1.631.999 2.645h3v2l-3.536.001A3.998 3.998 0 0116.5 21a3.998 3.998 0 01-3.464-1.999H7.163A3.5 3.5 0 01.5 17.5V11a1 1 0 01.883-.993L1.5 10h1V6a3 3 0 012.824-2.995L5.5 3h9zm2 12a2 2 0 100 4 2 2 0 000-4zM4 16a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm2.795-4H2.5v2.337A3.5 3.5 0 017.465 17H8.58l-1.786-5zm4.028-7H5.5a1 1 0 00-.993.883L4.5 6v4h3a1 1 0 01.892.548l.05.116L10.704 17H12.5c0-1.392.71-2.618 1.79-3.334L10.823 5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
