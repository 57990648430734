import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8.555 3v2H5.828c-.366 0-.703.2-.879.521L2.867 9.34 1.11 8.168 0 9.832l1.906 1.272-.228.417c-.08.147-.123.312-.123.479v9a1 1 0 001 1h2l-.006-2H18.56l-.006 2h2c.55 0 1-.45 1-1v-9a.993.993 0 00-.106-.447l-.23-.461 1.89-1.26L22 8.168l-1.682 1.121-1.869-3.736A1 1 0 0017.555 5h-3V3zM6.422 7h10.515l1.5 3H4.785zm-2.729 5h15.744l.118.236V18h-16v-5.744zm2.862 2a1 1 0 100 2 1 1 0 000-2zm10 0a1 1 0 100 2 1 1 0 000-2z" />
    </svg>
  )
}

export default SvgComponent
