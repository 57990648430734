import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.75 2a2 2 0 10-.001 3.999A2 2 0 0012.75 2zM6.443 6.273L5 12.243l1.943.47L8.39 6.744l-1.946-.47zM11.748 7c-.977 0-1.787.704-1.96 1.63l-.962 3.823.004.016c-.047.17-.08.346-.08.531 0 .621.29 1.17.734 1.537l.002.008 3.02 2.873 1.703 4.047.207.515 1.855-.742-1.892-4.732-.002-.002-1.736-2.848.043-.174c.009-.037.022-.073.029-.11l.549-2.186 1.029 1.45 3.47 1.262.684-1.878-2.884-1.05-2.02-2.843A1.997 1.997 0 0011.748 7zm-2.945 8.914l-.485 1.436-2.529 3.49 1.62 1.174 2.618-3.614a.999.999 0 00.139-.267l.227-.676z" />
    </svg>
  )
}

export default SvgComponent
