import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(1 3.5)"
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <path
          strokeWidth={2}
          d="M19.4 13.67h1.939V8.03l-1.88-3.76h-5.345M6.242 13.669h8.518M3.481 8.03v3.734"
        />
        <path strokeWidth={2} d="M14.76 2.39H.66v5.288l11.456 3.172z" />
        <circle strokeWidth={1.824} cx={3.951} cy={14.139} r={3} />
        <circle strokeWidth={1.824} cx={17.109} cy={14.139} r={3} />
        <path
          strokeWidth={2}
          strokeLinecap="round"
          d="M13.82 8.03h6.579M14.466 3.33l.865-2.82h3.776"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
