import {h} from 'preact'

function SvgComponent(props) {
  return (
    <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M15.998 6C17.088 6 18 6.848 18 7.963V10h6.67c.735 0 1.493.57 1.695 1.278l1.54 5.388h1.428c.736 0 1.334.599 1.334 1.338v6.659c0 .738-.601 1.337-1.334 1.337h-1.56a4.002 4.002 0 01-7.545 0h-4.895v-.009l-2.224.001a4.002 4.002 0 01-3.576 2.67l-.2.005a4.002 4.002 0 01-3.775-2.675H2.67a1.332 1.332 0 01-1.337-1.334 1.33 1.33 0 011.337-1.334h2.894a4.002 4.002 0 017.539 0h2.894c.05 0 .1.003.15.009h4.08a4.002 4.002 0 017.545 0H28v-4.009l-.517.002a1.317 1.317 0 01-.144.007H18l.001.01h-2.667V8.67L2.667 8.667a1.333 1.333 0 010-2.667zM24 23.333A1.333 1.333 0 1024 26a1.333 1.333 0 000-2.667zm-14.667 0a1.333 1.333 0 100 2.667 1.333 1.333 0 000-2.667zm14.674-10.666L18 12.666v4h7.143l-1.135-4z" />
        <path d="M1.333 7.111H4v17.778H1.333z" />
      </g>
    </svg>
  )
}

export default SvgComponent
