import {h} from 'preact'
import PropTypes from 'prop-types'

import './Education.scss'
import Block from './common/Block'
import Quote from './common/Quote'

Education.propTypes = {
  history: PropTypes.array,
  comment: PropTypes.array,
}

export default function Education({history, comment}) {
  if (!history && !comment) return null

  return (
    <Block className="Education" title="Education">
      {history && (
        <ul>
          {history.map((item) => (
            <li key={item.value} class="Education-Item">
              <h3 class="Education-Title">{item.value}</h3>
              {item.additionalValues &&
                item.additionalValues.map((item, index) => (
                  <p key={index} class="Education-Text">
                    {item}
                  </p>
                ))}
            </li>
          ))}
        </ul>
      )}
      {comment && <Quote text={comment[0].value} />}
    </Block>
  )
}
